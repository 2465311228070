import React from 'react'
import * as AntdComponents from 'antd'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import { useSelector } from 'react-redux'
import CustomButton from '../../../components/CustomButton'
import { ReactIcons } from '../../../utils/ReactIcons'
import { useDynamicMutationMutation } from '../../../redux/service/apiSlice'
import { AUTH } from '../../../utils/Endpoints'
import { QueryKeys } from '../../../utils/RTKKeys'
import CustomToast from '../../../utils/CustomToast'

const minutesArr = [
    {
        minute: 10,
        text: '10 Minutes',
    },
    {
        minute: 30,
        text: '30 Minutes',
    },
    {
        minute: 45,
        text: '45 Minutes',
    },
    {
        minute: 60,
        text: '1 Hour',
    },
    {
        minute: 120,
        text: '2 Hour',
    },
    {
        minute: 180,
        text: '3 Hour',
    },
    {
        minute: 300,
        text: '5 Hour',
    },
    {
        minute: 480,
        text: '8 Hour',
    },
]
const Security = () => {
    const userData = useSelector((state) => state?.auth?.user_detail)
    const [triggerDetailUpdate, { isLoading }] = useDynamicMutationMutation();

    // 2 factor authentication
    const [authenticateEnable, setAuthenticateEnable] = React.useState(false)
    const [twoFactorModal, setTwoFactorModal] = React.useState(false)
    const [selectAuthenticateMethod, setSelectAuthenticateMethod] = React.useState('email')

    // logout states
    const [logoutModal, setLogoutModal] = React.useState(false)
    const [logoutEnable, setLogoutEnable] = React.useState(false)
    const [minutes, setMinutes] = React.useState({
        value: 10,
    })


    React.useEffect(() => {
        setLogoutEnable(userData?.auto_logout)
        setAuthenticateEnable(userData?.two_factor_auth)
        setMinutes({
            value:userData?.auto_logout_time
        })
    }, [userData])

    const handleUpdate = async () => {
        let payload = {
            two_factor_auth: authenticateEnable,
            two_factor_source: selectAuthenticateMethod,
            auto_logout_time: minutes?.value,
            auto_logout: logoutEnable,
        }
        let requestData = {
            endpoint: AUTH.PROFILE.USER_PROFILE_UPDATE,
            method: 'PUT',
            body: payload,
            key:QueryKeys.USER_DETAIL
        };

        try {
            const { data, error } = await triggerDetailUpdate(requestData);
            if (data?.data) {
                CustomToast('s', data?.message || 'Updated');
            } else {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    }

    return (
        <>
            <div className='content_common'>
                <div className="text-center">
                    <AntdComponents.Typography className='titleMedium text-center'>Security</AntdComponents.Typography>
                    <AntdComponents.Avatar src={STATIC_IMAGES.SIDEBAR.SECURITY} size={150} />
                </div>
                <div className="grid place-items-center">
                    <div className="">
                        {/* 2 factor */}
                        <div className="mt-2">
                            <AntdComponents.Typography className='titleSmall'>2 Factor authentication</AntdComponents.Typography>
                            <div className="flex items-center gap-2 ps-6">
                                <AntdComponents.Switch value={authenticateEnable} onChange={(value) => { setAuthenticateEnable(value) }} />
                                <AntdComponents.Typography className='paragraph'>{authenticateEnable?'Enabled':'Enable'}</AntdComponents.Typography>
                            </div>
                            <div className="flex gap-2 ps-6 mt-3">
                                <AntdComponents.Avatar src={STATIC_IMAGES.EDIT} shape='square' size={30} className='cursor-pointer' onClick={() => {
                                    userData?.email && userData?.phone_number &&
                                        setTwoFactorModal(true)
                                }
                                } />
                                <AntdComponents.Typography className='paragraph'>Connected to this <span className='font-semibold'>email-address:</span> {userData?.email}</AntdComponents.Typography>
                            </div>
                        </div>
                        {/* automatic logout */}
                        <div className="mt-5">
                            <AntdComponents.Typography className='titleSmall'>Automatic log out</AntdComponents.Typography>
                            <div className="flex items-center gap-2 ps-6">
                                <AntdComponents.Switch value={logoutEnable} onChange={(value) => { setLogoutEnable(value) }} />
                                <AntdComponents.Typography className='paragraph'>{logoutEnable?'Enabled':'Enable'}</AntdComponents.Typography>
                            </div>
                            <div className="flex items-center gap-2 ps-6 mt-3" >
                                <AntdComponents.Avatar src={STATIC_IMAGES.EDIT} shape='square' size={30} className='cursor-pointer' onClick={() => setLogoutModal(true)} />
                                <AntdComponents.Typography className='paragraph'>Set to <span className='font-semibold'>log out</span> after <span className='font-semibold'>{minutes?.value} {minutes?.value<=45?'Minutes':'Hour'}</span></AntdComponents.Typography>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <CustomButton isLoading={isLoading} title='Update' className={'my-8'} onClick={handleUpdate} />
                    </div>
                </div>
            </div>

            {/* automatic log out */}
            {
                logoutModal &&
                <AntdComponents.Modal centered
                    open={logoutModal}
                    onCancel={() => setLogoutModal(false)}
                    footer={false}
                    width={500}
                    closeIcon={<ReactIcons.CloseIcon />}>
                    <div className="">
                        <AntdComponents.Typography className='titleSmall text-center text-Black font-semibold'>Auto Log Out</AntdComponents.Typography>
                        <div className="">
                            {
                                minutesArr?.map((ele, index) => {
                                    return (
                                        <div className="" key={index}>
                                            <AntdComponents.Typography className='paragraphSmall mt-1 cursor-pointer text-Black font-[500]'
                                                onClick={() => {
                                                    setMinutes({
                                                        value: ele?.minute,
                                                        text: ele?.text
                                                    }); setLogoutModal(false)
                                                }}
                                            >{ele?.text}</AntdComponents.Typography>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </AntdComponents.Modal>
            }
            {/* two factor auth */}
            {
                twoFactorModal &&
                <AntdComponents.Modal centered
                    open={twoFactorModal}
                    onCancel={() => setTwoFactorModal(false)}
                    footer={false}
                    width={500}
                    closeIcon={<ReactIcons.CloseIcon />}>
                    <div className="">
                        <AntdComponents.Typography className='titleSmall text-center text-Black leading-7 font-semibold'>Select a Two-Factor Authentication <br />Method</AntdComponents.Typography>
                        <div className="flex flex-col items-center mt-3">
                            <AntdComponents.Typography className={`text-Black font-bold bg-White text-para border-DarkPurple cursor-pointer ${selectAuthenticateMethod === userData?.email ? ' border-2' : 'border'} rounded-5 w-64 text-center py-3`}
                                onClick={() => setSelectAuthenticateMethod('email')}
                            >{userData?.email}</AntdComponents.Typography>
                            <AntdComponents.Typography className={`text-Black font-bold bg-White text-para border-DarkPurple cursor-pointer ${selectAuthenticateMethod === userData?.phone_number ? ' border-2' : 'border'} rounded-5 w-64 text-center py-3 mt-5`}
                                onClick={() => setSelectAuthenticateMethod('phone')}>{userData?.phone_number}</AntdComponents.Typography>
                        </div>
                    </div>
                </AntdComponents.Modal>
            }
        </>
    )
}

export default Security
