import React, { useState } from 'react'
import * as AntdComponents from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { ReactIcons } from '../../../utils/ReactIcons'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import { acceptImageType, fileUrl, onlyTenNumberInputs } from '../../../utils/Constant'
import CustomButton from '../../../components/CustomButton'
import { AUTH } from '../../../utils/Endpoints'
// import { QueryKeys } from '../../../utils/RTKKeys'
import { useDynamicMutationMutation } from '../../../redux/service/apiSlice'
import CustomToast from '../../../utils/CustomToast'
import LoaderCircle from '../../../components/Loader'
import { AuthData, LogoutAction } from '../../../redux/slices/authSlice'
import OutlineButton from '../../../components/OutlineButton'
import { QueryKeys } from '../../../utils/RTKKeys'

const ProfileComp = () => {
  const [profileForm] = AntdComponents.Form.useForm();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.auth?.user_detail);
  const usedPercentage = (userData?.storage_details?.storage_used / userData?.storage_details?.total_storage) * 100;
  const remainingPercentage = 100 - usedPercentage;
  const [profilePicUrl, setProfilePic] = useState(userData?.profile_pic || '');
  const [editableField, setEditableField] = useState(null);
  const [deleteModal, setDeleteModal] = React.useState(false)

  const [triggerProfileUpdate, { isLoading: profilePicLoading }] = useDynamicMutationMutation();
  const [triggerDetailUpdate,] = useDynamicMutationMutation();

  React.useEffect(() => {
    profileForm.setFieldsValue({
      name: userData?.user_name,
      email: userData?.email,
      phone: userData?.phone_number,
    });
  }, [userData]);

  const handleEditClick = (field) => {
    setEditableField(field);
  };

  // update handle
  const handleUpdateClick = async (field,profileUrl='') => {
    const values = profileForm.getFieldsValue();
    let payload = {
      user_name: values?.name,
      email: values?.email,
      phone_number: values?.phone?values?.phone:'',
      profile_pic:profileUrl|| profilePicUrl,
    };

    let requestData = {
      endpoint: AUTH.PROFILE.USER_PROFILE_UPDATE,
      method: 'PUT',
      body: payload,
    };

    try {
      const { data, error } = await triggerDetailUpdate(requestData);
      if (data?.data) {
        let updateObj = {
          user_name: data?.data?.user_name,
          email: data?.data?.email,
          phone_number: data?.data?.phone_number,
          profile_pic: data?.data?.profile_pic,
        };
        dispatch(AuthData(updateObj));
        setEditableField(null);
      } else {
        CustomToast('e', error?.data?.message || 'Something went wrong');
      }
    } catch (err) {
      CustomToast('e', 'An unexpected error occurred.');
    }
  };

  // file change and upload
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append('profile_pic', file);

      let requestData = {
        endpoint: AUTH.PROFILE.USER_PROFILE_PIC,
        method: 'POST',
        body: formData,
        key:QueryKeys.USER_DETAIL
      };

      try {
        const { data, error } = await triggerProfileUpdate(requestData);
        if (data?.data?.[0]) {
          setProfilePic(data?.data?.[0])
          handleUpdateClick('',data?.data?.[0])
        } else {
          CustomToast('e', error?.data?.message || 'Image upload failed');
        }
      } catch (err) {
        CustomToast('e', 'An error occurred while uploading');
      }
    }
  };
// delete account
  const handleDelete = async () => {
    let requestData = {
      endpoint: AUTH.PROFILE.DELETE_ACCOUNT,
      method: 'POST',
      body: {
        user_id: userData?._id
      },
    };

    try {
      const { data, error } = await triggerProfileUpdate(requestData);
      if (data?.data) {
        CustomToast('s', data?.message || 'Account has been deleted');
        dispatch(LogoutAction())
        setDeleteModal(false)
      } else {
        CustomToast('e', error?.data?.message || 'Image upload failed');
      }
    } catch (err) {
      CustomToast('e', 'An error occurred while uploading');
    }
  }

  return (
    <>
      <div className='content_common'>
        <div className="flex gap-5 mt-5">
          {/* Profile Picture */}
          <AntdComponents.Spin spinning={profilePicLoading} indicator={<LoaderCircle divHeight={'100%'} height='60' width='60' />}>
            <div className="relative">
              <AntdComponents.Avatar size={130} className='bg-GreyLight2' src={profilePicUrl ? `${fileUrl}${profilePicUrl}` : <ReactIcons.profileIcon className='text-GreyLight3 h-32' style={{ fontSize: 70 }} />}
                style={{ cursor: 'pointer' }}>
              </AntdComponents.Avatar>
              <div className="absolute top-1 left-1">
                <input id='profile_pic' type='file' accept={acceptImageType} className='hidden' onChange={handleFileChange} />
                <label htmlFor='profile_pic' className='cursor-pointer'>
                  <AntdComponents.Image src={STATIC_IMAGES.EDIT_PROFILE} height={40} preview={false} />
                </label>
              </div>
            </div>
          </AntdComponents.Spin>

          {/* Storage Info */}
          <div className="flex flex-col mt-3">
            <AntdComponents.Typography className='capitalize titleSmall'>{userData?.user_name}</AntdComponents.Typography>
            <AntdComponents.Progress
              className="w-52"
              percent={usedPercentage.toFixed(2)}
              showInfo={false}
              strokeColor={{
                '0%': 'var(--GreyLight2)',
                '100%': 'var(--GreyLight2)',
              }}
            />
            <div className="flex items-center gap-2">
              <AntdComponents.Avatar src={STATIC_IMAGES.CLOUD} size={40} shape='SQUARE' />
              <AntdComponents.Typography className='capitalize font-normal paragraphSmall'>{remainingPercentage.toFixed(2)}% Storage remaining</AntdComponents.Typography>
            </div>
          </div>
        </div>

        {/* Form Section */}
        <div className="mt-8 w-full md:w-1/3">
          <AntdComponents.Form form={profileForm} layout='vertical' requiredMark={false}>
            {/* Name Field */}
            <AntdComponents.Form.Item name="name" label="Name">
              <AntdComponents.Input
                className='input-box'
                placeholder='Your name'
                readOnly={editableField !== 'name'}
                suffix={
                  editableField === 'name' ? (
                    <AntdComponents.Typography className='text-DarkPurple font-bold cursor-pointer' onClick={() => handleUpdateClick('name')}>Update</AntdComponents.Typography>
                  ) : (
                    <AntdComponents.Avatar shape='square' role='button' size={30} src={STATIC_IMAGES.EDIT} onClick={() => handleEditClick('name')} />
                  )
                }
              />
            </AntdComponents.Form.Item>

            {/* Email Field (RESTORED) */}
            <AntdComponents.Form.Item name="email" label="Email">
              <AntdComponents.Input
                className='input-box'
                placeholder='Your email'
                readOnly={editableField !== 'email'}
                suffix={
                  editableField === 'email' ? (
                    <AntdComponents.Typography className='text-DarkPurple font-bold cursor-pointer' onClick={() => handleUpdateClick('email')}>Update</AntdComponents.Typography>
                  ) : (
                    <AntdComponents.Avatar shape='square' role='button' size={30} src={STATIC_IMAGES.EDIT} onClick={() => handleEditClick('email')} />
                  )
                }
              />
            </AntdComponents.Form.Item>

            {/* Phone Field */}
            <AntdComponents.Form.Item name="phone" label="Phone Number">
              <AntdComponents.Input
                className='input-box'
                placeholder='Phone Number'
                readOnly={editableField !== 'phone'}
                onKeyPress={(e) => onlyTenNumberInputs(e)}
                suffix={
                  editableField === 'phone' ? (
                    <AntdComponents.Typography className='text-DarkPurple font-bold cursor-pointer' onClick={() => handleUpdateClick('phone')}>Update</AntdComponents.Typography>
                  ) : (
                    <AntdComponents.Avatar shape='square' role='button' size={30} src={STATIC_IMAGES.EDIT} onClick={() => handleEditClick('phone')} />
                  )
                }
              />

            </AntdComponents.Form.Item>
            <CustomButton title={'Delete Account'} className={'mt-5'} onClick={() => setDeleteModal(true)} />
          </AntdComponents.Form>
        </div>
      </div>
      {
        deleteModal &&
        <AntdComponents.Modal centered open={deleteModal} onCancel={() => setDeleteModal(false)} footer={false} width={500} closeIcon={<ReactIcons.CloseIcon />}>
          <div className="text-center my-10">
            <AntdComponents.Image preview={false} src={STATIC_IMAGES.DELETE} alt="deletIcon" height={90} width={'auto'} />
            <AntdComponents.Typography className="titleSmall text_truncate">Are you sure want to delete this account?</AntdComponents.Typography>
            <div className="mt-4 flex items-center gap-5">
              <CustomButton title={'Delete Account'} isLoading={false} onClick={() => handleDelete()} />
              <OutlineButton title={'Cancel'} className={'text-Purple bg-White'} classNameDiv='m-auto w-full md:w-64' onClick={() => { setDeleteModal(false) }} />
            </div>
          </div>
        </AntdComponents.Modal>
      }
    </>
  );
};

export default ProfileComp;
