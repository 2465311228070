import React from 'react'
import * as AntdComponents from 'antd'
import { ReactIcons } from '../../../utils/ReactIcons'
import { AUTH } from '../../../utils/Endpoints'
import { QueryKeys } from '../../../utils/RTKKeys'
import { useDynamicQueryQuery } from '../../../redux/service/apiSlice'
import { fileNameAndUrl, fileUrl } from '../../../utils/Constant'
import moment from 'moment'
import CustomDivider from '../../../components/CustomDivider'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import SkeletonLoader from '../../../utils/Loader/SkeletonLoader'

const HistoryModal = ({ historyModalOpen, setHistoryModalOpen, selectData }) => {
    // get history detail
    const { data, isLoading } = useDynamicQueryQuery({
        endpoint: AUTH.HOME.HISTORY,
        params: { file_id: selectData?._id },
        key: QueryKeys.HISTORY_DETAIL
    }, { skip: !selectData?._id, refetchOnMountOrArgChange: true })
    let historyData = data?.data
    console.log('historyData', historyData);
    let { name, url } = fileNameAndUrl(selectData)

    return (
        <AntdComponents.Modal
            open={historyModalOpen}
            onCancel={() => setHistoryModalOpen(false)}
            footer={false}
            centered
            closeIcon={<ReactIcons.CloseIcon onClick={() => setHistoryModalOpen(false)} />}
            width={550}
        >
            <div className="flex gap-4">
                <AntdComponents.Avatar src={url} size={50} />
                <div className="">
                    <AntdComponents.Typography className='titleSmall'>{name}</AntdComponents.Typography>
                    <AntdComponents.Typography className='text-description font-normal text-DarkGrey'>Uploaded {moment(selectData?.createdAt).format('MMM DD, YYYY')}</AntdComponents.Typography>
                    {
                        selectData?.shared_by &&
                        <AntdComponents.Typography className='description'>Shared by {selectData?.shared_by}</AntdComponents.Typography>
                    }
                </div>
            </div>
            <CustomDivider className={'mt-4'} />
            <div className="mt-4 h-50 overflow-y-auto overflow-x-hidden">
                {
                    isLoading ?
                        <AntdComponents.Row gutter={[20, 20]}>
                            {
                                [1, 2, 3]?.map((_, index) => {
                                    return (
                                        <AntdComponents.Col xs={24} sm={24} md={24} lg={24} xl={24} key={index}>
                                            <SkeletonLoader />
                                        </AntdComponents.Col>
                                    )
                                })
                            }
                        </AntdComponents.Row>
                        :
                        <>
                            {
                                historyData?.length === 0 ?
                                    <div className='text-center mt-5'>
                                        <AntdComponents.Avatar src={STATIC_IMAGES.HISTORY} size={80} shape='square' />
                                        <AntdComponents.Typography className='titleSmall'>No History</AntdComponents.Typography>
                                    </div>
                                    :
                                    <>
                                        {
                                            historyData?.result?.map((item, index) => {
                                                let image = item?.profile_pic ? `${fileUrl}${item?.profile_pic}` : <ReactIcons.profileIcon className={'text-DarkGrey'} style={{ fontSize: 25 }} />
                                                return (
                                                    <div className="flex items-center gap-3 mt-3" key={index}>
                                                        <AntdComponents.Avatar src={image} size={50} shape={'circle'} className='bg-GreyLight2' />
                                                        <div>
                                                            <div className="flex items-center gap-2">
                                                                <AntdComponents.Typography className="paragraph text-start text_truncate max-w-66 md:max-w-120 lg:max-w-200">
                                                                    {item?.first_name} {item?.last_name}
                                                                </AntdComponents.Typography>
                                                                {
                                                                    item?.view_count > 0 &&
                                                                    <AntdComponents.Popover trigger={'click'} arrow={false} content={<AntdComponents.Typography className='font-bold p-3'>File has been viewed {item?.count} times</AntdComponents.Typography>}>
                                                                        <ReactIcons.InfoIcon size={22} color={'var(--DarkGrey)'} />
                                                                    </AntdComponents.Popover>
                                                                }
                                                            </div>
                                                            <AntdComponents.Typography className="description text-start">
                                                                <span className='font-semibold'>{item?.action}</span> <span>file</span> {moment(item?.updatedAt).format('lll')}
                                                            </AntdComponents.Typography>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                            }
                        </>
                }
            </div>

        </AntdComponents.Modal>
    )
}

export default HistoryModal
