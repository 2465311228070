export const QueryKeys = {
    AREA_SCREEN:'area_screen',
    WEATHER_SCREEN:'weather_screen',
    HOSPITAL_SCREEN:'hospital_screen',
    FOLDER_LIST:'folder_list',
    FOLDER_DETAIL:'folder_detail',
    HISTORY_DETAIL:'history-detail',
    DOCUMENT_DETAIL:'document_detail',
    COMMON_DATA:'common-data',
    TRASH_LIST:'trash-list',
    ALERT_LIST:'alert-list',
    CONTACT_LIST:'contact-list',
    CONTACT_DETAIL:'contact_detail',
    CONTACT_ACCESS_LEVEL:'contact-access-level',
    NOTIFICATION_LIST:'notification-list',
    SUBSCRIPTION_LIST:'subscription-list',
    USER_DETAIL:'user-detail',
}
export const MutateKeys = {
    CREATE_FOLDER:'create_folder',
    DOCUMENT_UPLOAD:'document_upload',
}