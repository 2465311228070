import React, { useRef } from 'react'
import Webcam from 'react-webcam'
import CustomButton from './CustomButton';
import OutlineButton from './OutlineButton';
import LoaderTheme from './LoaderTheme';
import { Typography } from 'antd';
import { ReactIcons } from '../utils/ReactIcons';

const UploadCameraView = ({ setImageFile,setOpenCameraView,cameraHandle }) => {
    const webcamRef = useRef(null);
    const [imagePreview, setImagePreview] = React.useState(null);
    const [cameraLoading, setCameraLoading] = React.useState(true);
    const captureImage = () => {
        const imageSrc = webcamRef.current.getScreenshot(); // Get image as Base64
        setImagePreview(imageSrc);

        // Convert Base64 to File Object
        fetch(imageSrc)
            .then((res) => res.blob())
            .then((blob) => {
                const file = new File([blob], "captured-image.jpg", { type: "image/jpeg" });
                setImageFile(file);
                console.log("Captured File:", file);
            });
    };

    // onCamera ready
    const onCameraReady = () => {
        console.log('ready');
        setCameraLoading(false);  // Camera is ready, stop loading indicator
    };
    return (
        <>
        <ReactIcons.BackIcon size={30} onClick={()=>setOpenCameraView(false)}/>
            <div className='mt-8 w-full'>
                {imagePreview ?
                    <div className='relative'>
                        <img src={imagePreview} alt="Captured" className="w-full" />
                    </div>
                    :
                    <>
                        {
                            cameraLoading &&
                            <div className='grid place-content-center place-items-center mt-48'>
                                <LoaderTheme />
                                <Typography className='font-bold text-center text-title20'>Camera Loading</Typography>
                            </div>
                        }
                        {
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                className="w-full"
                                mirrored={true}
                                onUserMedia={onCameraReady}
                            />
                        }
                    </>

                }
            </div>
            <div className="text-center mt-8" >
                {
                    imagePreview ?
                        <div className='flex items-center justify-center gap-10 w-full'>
                            <CustomButton title='Retake' className={''} onClick={() => { setImagePreview(false); setCameraLoading(true) }} />
                            <OutlineButton title={'Continue'} className={'text-Purple bg-White'} classNameDiv='md:w-52' onClick={()=>cameraHandle()} />
                        </div>
                        :
                        <div>
                            <CustomButton title='Capture' className={'w-fit px-10'} onClick={() => captureImage()} />
                        </div>

                }

            </div>
        </>
    )
}

export default UploadCameraView