import * as AntdComponents from 'antd'
import React from 'react'
import { SidebarList } from './SidebarList';
import { Outlet, useNavigate } from 'react-router-dom';
import { STATIC_IMAGES } from '../utils/StaticImages';
import { ReactIcons } from '../utils/ReactIcons';
import { RouterKeys } from '../Routes/RouterKeys';
import { LogoutAction, useDetailStore } from '../redux/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import useDynamicMediaQuery from '../Hook/useDynamicMediaQuery';
import { fileUrl } from '../utils/Constant';
import { useDynamicMutationMutation, useDynamicQueryQuery } from '../redux/service/apiSlice';
import { AUTH, NON_AUTH } from '../utils/Endpoints';
import { QueryKeys } from '../utils/RTKKeys';
import useAutoLogout from '../Hook/useAutoLogout';
import CustomToast from '../utils/CustomToast';
const { Header, Sider, Content } = AntdComponents.Layout;

const MainLayout = () => {
  const getDeviceId = useSelector((state) => state?.auth?.deviceId)
  const userData = useSelector((state) => state?.auth?.auth_data)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = React.useState(RouterKeys.NON_Auth.HOME.HOME);
  const isTab = useDynamicMediaQuery(540, true);
  const [triggerLogoutFunction] = useDynamicMutationMutation();
  const [notificationCount, setNotificationCount] = React.useState()
  const [triggerSendClick] = useDynamicMutationMutation();

  // logout function
  const logOut = async () => {
    let payload = {
      device_id: getDeviceId
    }
    let requestData = {
      endpoint: NON_AUTH.LOGOUT,
      method: 'POST',
      body: payload,
    };
    try {
      let { data } = await triggerLogoutFunction(requestData);
      if (data?.data) {
        dispatch(LogoutAction())
      }
    } catch (err) {
      console.log('e', 'An unexpected error occurred.');
    }
  }
  // notification
  const { data: notificationData } = useDynamicQueryQuery({
    endpoint: AUTH.NOTIFICATION.NOTIFICATION_LIST,
    params: {},
    key: QueryKeys.NOTIFICATION_LIST
  },
    { refetchOnMountOrArgChange: true }
  )
  React.useEffect(() => {
    setNotificationCount(notificationData?.data?.unreadCount||0)
  }, [notificationData])

  // user detail
  const { data } = useDynamicQueryQuery({
    endpoint: AUTH.PROFILE.USER_DETAIL,
    params: {},
    key: QueryKeys.USER_DETAIL
  },
    { refetchOnMountOrArgChange: true }
  )

  React.useEffect(() => {
    dispatch(useDetailStore(data?.data))
  }, [data?.data])

  // auto logout
  if(userData?.auto_logout){
    let myDevice = userData?.login_device_info?.find((i) => i?.device_id === getDeviceId)
    useAutoLogout(myDevice?.device_last_login, userData?.auto_logout_time);
  }

  // Setting Active sider
  React.useEffect(() => {
    const pathWithoutQuery = location.pathname.split('?')[0]; // Remove query parameters
    // Define a map of paths to active indexes
    const pathToIndexMap = {
      [`${RouterKeys.NON_Auth.HOME.HOME}`]: `${RouterKeys.NON_Auth.HOME.HOME}`,
      [`${RouterKeys.NON_Auth.HOME.FOLDER_DOCUMENTS}`]: `${RouterKeys.NON_Auth.HOME.HOME}`,
      [`${RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEYS}`]: `${RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEYS}`,
      [`${RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST}`]: `${RouterKeys.NON_Auth.TUTORIALS.TUTORIAL_TRAINING}`,
      [`${RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEY_PAGE}`]: `${RouterKeys.NON_Auth.TUTORIALS.TUTORIAL_TRAINING}`,
      [`${RouterKeys.NON_Auth.TRASH.TRASH}`]: `${RouterKeys.NON_Auth.TRASH.TRASH}`,
      [`${RouterKeys.NON_Auth.CONTACTS.ADD_CONTACTS}`]: `${RouterKeys.NON_Auth.CONTACTS.CONTACTS}`,
    };

    // Check for a direct match in the map
    if (pathToIndexMap[pathWithoutQuery]) {
      setActiveIndex(pathToIndexMap[pathWithoutQuery]);
    } else {
      // Fallback to the pathname if no match is found
      setActiveIndex(pathWithoutQuery);
    }
  }, [location.pathname]);

  const handleMenuClick = (e) => {
    setActiveIndex(e.key);
  };
  const popOverContent = (
    <div className='px-4 py-3'>
      <AntdComponents.Typography className="flex items-center  gap-4 cursor-pointer" onClick={() => navigate(RouterKeys.NON_Auth.PROFILE.PROFILE)}>
        <AntdComponents.Typography className="text-description text-Purple" >Profile</AntdComponents.Typography>
        <ReactIcons.profileIcon className={'text-Purple'} style={{ fontSize: 25, marginBottom: 5 }} />
      </AntdComponents.Typography>
      <AntdComponents.Typography className="flex items-center gap-3 cursor-pointer mt-2" onClick={() => logoutHandle()}>
        <AntdComponents.Typography className="text-description text-Purple">Logout</AntdComponents.Typography>
        <ReactIcons.LogOutIcon className={'text-Purple'} />
      </AntdComponents.Typography>
    </div>
  );
  const logoutHandle = () => {
    logOut()
  }
  const openNotification = async () => {
    navigate(RouterKeys.NON_Auth.GENERAL.NOTIFICATION)
    let requestData = {
      endpoint: AUTH.NOTIFICATION.NOTIFICATION_READ_ALL,
      method: 'POST',
      body: {
        "type": 'simple',
        "device_id": getDeviceId
      },
      key:QueryKeys.NOTIFICATION_LIST
    };

    try {
      const { data, error } = await triggerSendClick(requestData);
      if (data) {
        CustomToast('s', data?.message);
      } else {
        CustomToast('e', error?.data?.message || 'Something went wrong');
      }
    } catch (err) {
      CustomToast('e', 'An unexpected error occurred.');
    }

  }
  return (
    <div>
      <AntdComponents.Layout>
        <Sider theme='light' width="15%" className='shadow-sideShadow h-screen overflow-y-auto myAside'>
          <div className="h-24 bg-White text-center flex justify-center items-center px-2 md:px-5">
            {
              isTab ?
                <AntdComponents.Image src={STATIC_IMAGES.LOGO} preview={false} height={40} role='button' onClick={() => navigate(RouterKeys.NON_Auth.HOME)} />
                :
                <AntdComponents.Avatar src={STATIC_IMAGES.LOGO_SMALL} size={40} shape='square' role='button' onClick={() => navigate(RouterKeys.NON_Auth.HOME)} className='cursor-pointer' />
            }
          </div>
          <AntdComponents.Menu
            mode="inline"
            selectable={true}
            selectedKeys={[activeIndex]}
            onClick={handleMenuClick}
            defaultSelectedKeys={["Home"]}
          >
            {
              SidebarList?.map((item) => {
                const isActive = activeIndex === item.routes;
                return (
                  <>
                    <AntdComponents.Menu.Item
                      key={item?.title}
                      onClick={() => { navigate(`${item?.routes}`) }}
                      className={`bg-LightPink  text-para  ${isActive ? 'text-DarkPurple font-semibold' : 'text-Black font-[500]'}`}
                    >
                      <div className='flex items-center'>
                        <span>
                          {isActive ? item?.active_icon : item?.in_active_icon} &nbsp;&nbsp;
                        </span>
                        <span className='hidden md:block'>
                          {item?.title}
                        </span>
                      </div>
                    </AntdComponents.Menu.Item>
                  </>
                )
              })
            }
          </AntdComponents.Menu>
          {/* <div className="absolute w-full gap-3 bottom-0.5 p-2">
          </div> */}
        </Sider>
        <AntdComponents.Layout className='w-full'>
          <Header className='h-24 bg-LightPink'>
            <div className="flex justify-end items-center gap-2 md:gap-4 w-[100%] h-[100%]">
              {/* <div className="flex justify-center gap-2">
                <AntdComponents.Avatar size={50} style={{ cursor: 'pointer' }}>
                  <span className='titleLarge'>{userData ? userData?.user_name?.charAt(0)?.toUpperCase() : 'U'}</span>
                </AntdComponents.Avatar>
                <div className="flex flex-col">
                  <AntdComponents.Typography className='capitalize titleSmall'>{userData?.user_name}</AntdComponents.Typography>
                  <AntdComponents.Progress
                    percent={usedPercentage.toFixed(2)}
                    showInfo={false}
                    strokeColor={{
                      from: 'var(--darkPurple)',
                      to: 'var(--MainPurple)'
                    }}
                  />
                  <AntdComponents.Typography className='capitalize font-normal paragraphSmall'>Storage Remaining: {remainingPercentage.toFixed(2)}%</AntdComponents.Typography>
                </div>
              </div> */}
              <div className="flex items-center gap-4">
                <div className="flex items-center justify-center gap-3">
                  <AntdComponents.Typography className='text-para text-center text-DarkPurple font-semibold cursor-pointer hover:text-Purple' onClick={() => navigate(RouterKeys.NON_Auth.GENERAL.Privacy)}>Privacy Policy</AntdComponents.Typography>
                  <AntdComponents.Typography className='text-para text-center text-DarkPurple font-semibold cursor-pointer hover:text-Purple' onClick={() => navigate(RouterKeys.NON_Auth.GENERAL.TERMS)}>Terms & Conditions</AntdComponents.Typography>
                </div>
                <AntdComponents.Badge count={notificationCount}>
                  <div className="bg-White p-2 text-center rounded-10" >
                    <ReactIcons.NotificationIcon style={{ fontSize: 30, cursor: 'pointer' }} className={'text-DarkPurple'} onClick={() => openNotification()} />
                  </div>
                </AntdComponents.Badge>
                <AntdComponents.Popover content={popOverContent} placement='topRight' arrow={false}>
                  {
                    userData?.profile_pic ?
                      <AntdComponents.Avatar size={50} style={{ cursor: 'pointer' }} src={userData?.account_source === 'social' ? userData?.profile_pic : `${fileUrl}${userData?.profile_pic}`} />
                      :
                      <AntdComponents.Avatar size={50} style={{ cursor: 'pointer' }} className='bg-DarkPurple'><span className='text-title24'>{userData?.user_name?.charAt(0)?.toUpperCase()}</span></AntdComponents.Avatar>
                  }
                </AntdComponents.Popover>
              </div>
            </div>
          </Header>
          <Content style={{ maxHeight: 'calc(100vh - 6rem)' }} className='bg-White px-2 md:px-50 py-5 overflow-y-auto'>
            <Outlet />
          </Content>
        </AntdComponents.Layout>
      </AntdComponents.Layout>
    </div>
  )
}

export default MainLayout
