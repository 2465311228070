import { BsPersonAdd, BsThreeDots } from "react-icons/bs";
import { CiSettings } from "react-icons/ci";
import { FaFacebook, FaPauseCircle, FaStar, FaUser } from "react-icons/fa";
import { IoChevronBackCircle, IoCloseCircle, IoNotificationsOutline, IoSearchOutline } from "react-icons/io5";
import {  RiLogoutCircleRLine } from "react-icons/ri";
import { BiShareAlt } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";
import { FiInfo } from "react-icons/fi";
import { TbFolderPlus } from "react-icons/tb";
import { PiCheckBold, PiCropBold } from "react-icons/pi";


export const ReactIcons = {
    BackIcon: ({ style, className, onClick,size=30 }) => <IoChevronBackCircle size={size} style={{ ...style}} className={`${className} cursor-pointer text-DarkPurple`} onClick={onClick} />,
    SettingIcon: ({ style, className, onClick }) => <CiSettings style={style} className={`${className} cursor-pointer`} onClick={onClick} />,
    NotificationIcon: ({ style, className, onClick }) => <IoNotificationsOutline style={style} className={`${className} cursor-pointer`} onClick={onClick} />,
    SearchIcon: ({ style, className, onClick }) => <IoSearchOutline style={{ ...style, fontSize: 20, }} className={`${className} cursor-pointer`} onClick={onClick} />,
    CloseIcon: ({ style, className, onClick,size=30 }) => <IoCloseCircle size={size} style={{ ...style }} className={`text-DarkPurple cursor-pointer ${className}`} onClick={onClick} />,
    ThreeDotIcon: ({ style, className, onClick }) => <BsThreeDots style={{ ...style, fontSize: 30, }} className={`text-Black cursor-pointer ${className}`} onClick={onClick} />,
    LogOutIcon: ({ style, className, onClick }) => <RiLogoutCircleRLine style={{ ...style, fontSize: 28, }} className={`text-Black cursor-pointer ${className}`} onClick={onClick} />,
    FbIcon: ({ style, className, onClick }) => <FaFacebook color="#1877F2" style={{ ...style,fontSize: 60, }} className={`${className} cursor-pointer`} onClick={onClick} />,
    shareIcon: ({ style, className, onClick }) => <BiShareAlt  style={{ ...style,fontSize: 30, }} className={`${className} cursor-pointer`} onClick={onClick} />,
    profileIcon: ({ style, className, onClick }) => <FaUser  style={{ ...style }} className={`${className} cursor-pointer`} onClick={onClick} />,
    starIcon: ({ style, className, onClick,size,color }) => <FaStar size={size} color={color} style={{ ...style }} className={`${className} cursor-pointer`} onClick={onClick} />,
    PauseIcon: ({ style, className, onClick,size,color }) => <FaPauseCircle size={size} color={color} style={{ ...style }} className={`${className} cursor-pointer`} onClick={onClick} />,
    LeftArrow: ({ style, className, onClick,size,color }) => <FaCircleArrowLeft size={size} color={color} style={{ ...style }} className={`${className} cursor-pointer`} onClick={onClick} />,
    RightArrow: ({ style, className, onClick,size,color }) => <FaCircleArrowRight size={size} color={color} style={{ ...style }} className={`${className} cursor-pointer`} onClick={onClick} />,
    HamBurgerIcon: ({ style, className, onClick,size,color }) => <GiHamburgerMenu size={size} color={color} style={{ ...style }} className={`${className} cursor-pointer`} onClick={onClick} />,
    InfoIcon: ({ style, className, onClick,size,color }) => <FiInfo size={size} color={color} style={{ ...style }} className={`${className} cursor-pointer`} onClick={onClick} />,
    AddFolderIcon: ({ style, className, onClick,size,color }) => <TbFolderPlus size={size} color={color} style={{ ...style }} className={`${className} cursor-pointer`} onClick={onClick} />,
    CropIcon: ({ style, className, onClick,size,color }) => <PiCropBold size={size} color={color} style={{ ...style }} className={`${className} cursor-pointer`} onClick={onClick} />,
    RightCheckIcon: ({ style, className, onClick,size,color }) => <PiCheckBold size={size} color={color} style={{ ...style }} className={`${className} cursor-pointer`} onClick={onClick} />,
    PersonAdd: ({ style, className, onClick,size,color }) => <BsPersonAdd size={size} color={color} style={{ ...style }} className={`${className} cursor-pointer`} onClick={onClick} />,
}