import React, { useRef } from 'react';
import Header from './Header';
import * as AntdComponents from 'antd';
import { landingImages } from './landingImages';
import { ReactIcons } from '../../utils/ReactIcons';
import { fileUrl } from '../../utils/Constant';
const data = [
  {
    text: 'Lorem ipsum dolor sit amet consectetur. Ut nec egestas ultrices adipiscing eu magna egestas euismod.',
    rate: 5,
    user: 'John Doe , 24'
  },
  {
    text: 'Lorem ipsum dolor sit amet consectetur. Ut nec egestas ultrices adipiscing eu magna egestas euismod.',
    rate: 5,
    user: 'John Doe , 24'
  },
  {
    text: 'Lorem ipsum dolor sit amet consectetur. Ut nec egestas ultrices adipiscing eu magna egestas euismod.',
    rate: 5,
    user: 'John Doe , 24'
  },
  {
    text: 'Lorem ipsum dolor sit amet consectetur. Ut nec egestas ultrices adipiscing eu magna egestas euismod.',
    rate: 5,
    user: 'John Doe , 24'
  },
  {
    text: 'Lorem ipsum dolor sit amet consectetur. Ut nec egestas ultrices adipiscing eu magna egestas euismod.',
    rate: 5,
    user: 'John Doe , 24'
  },
  {
    text: 'Lorem ipsum dolor sit amet consectetur. Ut nec egestas ultrices adipiscing eu magna egestas euismod.',
    rate: 5,
    user: 'John Doe , 24'
  },
]
const LandingMain = () => {
  // const getScreenWidth = window.innerWidth
  // const isLarge = getScreenWidth <= 1600
  const sliderRef = useRef()
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const handlePlayPause = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };
  const date = new Date()
  return (
    <div className="relative">
      {/* Fixed Header (Always on Top) */}
      <Header />
      {/* Background Image (Only Covering Left Side Content) */}
      <div className="absolute -top-24 -left-32 lg:w-70 xl:w-1/2 h-[800px] -z-10">
        <div
          className="bg-cover bg-no-repeat bg-center h-full"
          style={{ backgroundImage: `url(${landingImages.WELCOME})` }}
        ></div>
      </div>

      {/* Content Section */}
      <div className="">
        {/* welcome */}
        <section id='home' className="relative pt-48 common_container">
          <AntdComponents.Row align={'middle'} gutter={[20, 20]}>
            {/* Left side - Inside the Background */}
            <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="relative z-10 text-white p-10">
                <div className="bg-LightPurple rounded-10 flex items-center gap-2 w-fit ps-3 pe-5 py-2">
                  <ReactIcons.starIcon size={20} color={'#54007A'} />
                  <div>
                    <AntdComponents.Typography className="font-[500] text-DarkPurple paragraphSmall italic">
                      <span className="font-bold">Rated #1</span> Editors Choice App of 2024
                    </AntdComponents.Typography>
                  </div>
                </div>
                <div>
                  <AntdComponents.Typography className="italic titleExtraLarge font-[500] my-3">
                    Welcome to <span className="font-bold text-DarkPurple">VAULT</span>
                    <span className="font-bold text-Black"> PD!</span>
                  </AntdComponents.Typography>
                  <AntdComponents.Typography className="font-[500] text-LandingBlack paragraph">
                    Your vault helps you protect those you care about and your things. To get started, let’s explore how you can prepare ahead of challenges.
                  </AntdComponents.Typography>
                  <AntdComponents.Button type='ghost' className="landing_button w-full md:w-56 mt-10">
                    Try for Free
                  </AntdComponents.Button>
                </div>
              </div>
            </AntdComponents.Col>
            {/* Right side - Image */}
            <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div>
                <AntdComponents.Image src={landingImages.WELCOME_LEFT} preview={false} />
              </div>
            </AntdComponents.Col>
          </AntdComponents.Row>
        </section>
        {/* About us */}
        <section id='about' className='bg-cover bg-no-repeat bg-center bg-about_us_back mt-16'>
          <div className="common_container pt-20 pb-16">
            <AntdComponents.Row align={'middle'} gutter={[20, 20]}>
              <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12} className=''>
                <div className="">
                  <AntdComponents.Image src={landingImages.ABOUT_US} preview={false} />
                </div>
              </AntdComponents.Col>
              <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12} className=''>
                <div className="lg:ps-10">
                  <AntdComponents.Typography className="titleExtraLarge font-[500] my-3">
                    About <span className="font-bold text-DarkPurple italic">VAULT</span>
                    <span className="font-bold text-LandingBlack italic"> PD!</span>
                  </AntdComponents.Typography>
                  <AntdComponents.Typography className='paragraphSmall font-normal text-LandingBlack w-full xl:w-85'>Lorem ipsum dolor sit amet consectetur. Lacus et in viverra rhoncus massa id. Nam blandit egestas nunc tellus sodales etiam. Eu volutpat porttitor rhoncus turpis ut morbi in. Massa nunc lacus sit arcu scelerisque bibendum nisl urna habitant. Dignissim nec quis ultrices nibh erat sed ipsum aliquam. Morbi nulla sed vehicula nunc aliquam eleifend dui ut purus. Et mattis eu tincidunt velit at hendrerit. Pellentesque odio augue lacus maecenas. Urna sed vitae tristique viverra phasellus diam nulla pharetra..</AntdComponents.Typography>
                  <AntdComponents.Button type='ghost' className="landing_button w-full md:w-56 mt-10">
                    Contact Us
                  </AntdComponents.Button>
                </div>
              </AntdComponents.Col>
            </AntdComponents.Row>
          </div>
        </section>
        {/* Awesome features */}
        <section id='features' className='bg-cover bg-no-repeat bg-center bg-awesome_back mt-16 min-h-96'>
          <div className="common_container pt-10 pb-20">
            <AntdComponents.Typography className="titleExtraLarge text-center  font-[500]">
              Awesome <span className="font-bold text-DarkPurple italic">Features</span>
              <span className="font-bold text-Black italic"> PD!</span>
            </AntdComponents.Typography>
            <AntdComponents.Typography className='text-title20 font-normal text-LandingBlack text-center mt-3'>To get started, dive into our training videos and adding your trusted contacts to your account.</AntdComponents.Typography>
            <div className="mt-10">
              <AntdComponents.Row>
                <AntdComponents.Col xs={24} sm={24} md={24} lg={8} xl={8} className=''>
                  <div className="flex flex-col items-center">
                    <div className="awesome_box">
                      <AntdComponents.Image src={landingImages.ADD} height={60} className='mt-9 xl:mt-11' />
                    </div>
                    <div className="text-center mt-5">
                      <AntdComponents.Typography className='font-semibold paragraph'>Add a trusted person</AntdComponents.Typography>
                      <AntdComponents.Typography className='paragraphSmall font-normal'>Vault PD Share information with the people<br /> you trust</AntdComponents.Typography>
                    </div>
                  </div>
                </AntdComponents.Col>
                <AntdComponents.Col xs={24} sm={24} md={24} lg={8} xl={8} className=''>
                  <div className="flex flex-col items-center">
                    <div className="awesome_box">
                      <AntdComponents.Image src={landingImages.CHECKLIST} height={60} className='mt-9 xl:mt-11' />
                    </div>
                    <div className="text-center mt-5">
                      <AntdComponents.Typography className='font-semibold paragraph'>Document checklists</AntdComponents.Typography>
                      <AntdComponents.Typography className='paragraphSmall font-normal'>Be prepared and organized by uploading key<br /> documents</AntdComponents.Typography>
                    </div>
                  </div>
                </AntdComponents.Col>
                <AntdComponents.Col xs={24} sm={24} md={24} lg={8} xl={8} className=''>
                  <div className="flex flex-col items-center">
                    <div className="awesome_box">
                      <AntdComponents.Image src={landingImages.SHARE} height={60} className='mt-9 xl:mt-11' />
                    </div>
                    <div className="text-center mt-5">
                      <AntdComponents.Typography className='font-semibold paragraph'>Document checklists</AntdComponents.Typography>
                      <AntdComponents.Typography className='paragraphSmall font-normal'>Be prepared and organized by uploading key<br /> documents</AntdComponents.Typography>
                    </div>
                  </div>
                </AntdComponents.Col>
              </AntdComponents.Row>
            </div>
          </div>
        </section>
        {/* VIDEO PART */}
        <section>
          <div className="common_container h-[80vh] relative mt-10">
            <video className='h-full w-full' ref={videoRef}>
              <source src={`${fileUrl}web_static/video.mp4`} type="video/mp4" />
              <track
                default
                kind="captions"
                srcLang="en"
                // src="path/to/captions.vtt" // Replace with the actual path if available
                label="English"
              />
            </video>

            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2">
              {
                isPlaying ?
                  <ReactIcons.PauseIcon size={90} color={'#fff'} onClick={handlePlayPause} />
                  :
                  <AntdComponents.Image role='button' preview={false} height={100} src={landingImages.PLAY_ICON} onClick={handlePlayPause} />
              }
            </div>
          </div>
        </section>
        {/* reviews */}
        <section className='mt-16'
          style={{
            position: 'relative',
            height: '580px',
            overflow: 'hidden',
          }}
        >
          <div className="common_container left-1/2 transform -translate-x-1/2" style={{ position: 'absolute', top: 0, zIndex: 1 }}>
            <AntdComponents.Typography className="titleExtraLarge text-center font-[500] mt-8">
              What Other <span className="font-bold text-DarkPurple italic">Users Say</span>
            </AntdComponents.Typography>
            <AntdComponents.Typography className='text-title20 font-normal text-center text-LandingBlack mt-3'>Lorem ipsum dolor sit amet consectetur. Nec convallis amet enim hendrerit eget ipsum. Iaculis at blandit<br /> proin elit urna. Vestibulum pellentesque ultricies aliquam.</AntdComponents.Typography>
            <div className="mt-5 relative">
              <AntdComponents.Carousel
                slidesToShow={3}
                slidesToScroll={3}
                ref={sliderRef}
                infinite={false}
                autoplaySpeed={2000}
                responsive={[
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    }
                  },
                  {
                    breakpoint: 572,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    }
                  },
                ]}
              >
                {
                  data?.map((ele, index) => {
                    return (
                      <div key={index} className='p-3'>
                        <div className="shadow-landing_review_shadow bg-White rounded-20 p-3 mt-5">
                          <AntdComponents.Image preview={false} src={landingImages.QUOTES_IMAGE} height={60} />
                          <AntdComponents.Typography className='paragraph mt-5'>{ele?.text}</AntdComponents.Typography>
                          <div className="mt-4">
                            <AntdComponents.Typography className='paragraph font-semibold mt-1'>{ele?.user}</AntdComponents.Typography>
                            <AntdComponents.Rate value={ele?.rate} />
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </AntdComponents.Carousel>
              <div className="absolute -bottom-14 w-full">
                <div className="flex justify-between items-center">
                  <ReactIcons.LeftArrow color={`var(--darkPurple)`} onClick={() => sliderRef.current.prev()} size='30' className={''} />
                  <ReactIcons.RightArrow color='var(--darkPurple)' onClick={() => sliderRef.current.next()} size='30' className={''} />
                </div>
              </div>
            </div>
          </div>
          {/* back design*/}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: '100%',
              transform: 'translateX(-80%)',
              width: '100%',
              height: '100%', // This will only cover half of the section's height
              backgroundColor: '#FAF7FB',
              borderRadius: '0px 0px 20px 460px',
              zIndex: 0,
            }}
          />
        </section>
        {/* download */}
        <section id='download' className='mt-16'>
          <AntdComponents.Typography className="titleExtraLarge text-center font-[500]">
            Download <span className="font-bold text-DarkPurple italic">the App Now</span>
          </AntdComponents.Typography>
          <AntdComponents.Typography className='text-title20 font-normal text-center text-LandingBlack mt-3'>Lorem ipsum dolor sit amet consectetur. Nec convallis amet enim hendrerit eget ipsum. Iaculis at blandit<br /> proin elit urna. Vestibulum pellentesque ultricies aliquam.</AntdComponents.Typography>
          <div className="flex gap-10 items-center justify-center mt-10 mb-14">
            <AntdComponents.Image src={landingImages.ANDROID_IMAGE} preview={false} height={60} />
            <AntdComponents.Image src={landingImages.IOS_DOWNLOAD_IMAGE} preview={false} height={60} />
          </div>
          <div className="">
            <AntdComponents.Image src={landingImages.DOWNLOAD_BACK} preview={false} />
          </div>
        </section>
        {/* join news latter */}
        {/* <section className='bg-cover bg-no-repeat bg-center bg-join_back_image mt-16 min-h-96'>
          <div className="common_container py-10">
            <AntdComponents.Typography className="titleExtraLarge text-center text-White font-[500]">
              Join Our <span className="font-bold italic">Newsletter</span>
            </AntdComponents.Typography>
            <AntdComponents.Typography className='text-title20 font-normal text-center text-White mt-3'>Lorem ipsum dolor sit amet consectetur. Nec convallis amet enim hendrerit eget ipsum. Iaculis at blandit<br /> proin elit urna. Vestibulum pellentesque ultricies aliquam.</AntdComponents.Typography>
            <div className="text-center mt-5">
              <AntdComponents.Input className='w-[450px] landing_input h-10' placeholder='Email*' />
            </div>
            <div className="text-center">
              <AntdComponents.Button type='ghost' className="landing_button w-full md:w-56 mt-10">
                Subscribe
              </AntdComponents.Button>
            </div>
          </div>
        </section> */}
        {/* CONTACT US */}
        <section id='contact_su' className='py-16 mt-7 bg-[#fbfafc]'>
          <div className="common_container">
            <AntdComponents.Row gutter={[20,20]}>
              <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="h-full">
                  <AntdComponents.Image src={landingImages.CONTACT_US} preview={false} />
                </div>
              </AntdComponents.Col>
              <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12} className=''>
                <div className="lg:ps-10">
                  <AntdComponents.Typography className="titleExtraLarge font-[500]">
                    Contact <span className="font-bold text-DarkPurple italic">Us</span>
                  </AntdComponents.Typography>
                  <AntdComponents.Typography className='paragraph mt-2'>We’re here to help!<br /> We look forward to hearing from you.</AntdComponents.Typography>
                  {/* form */}
                  <div className="mt-5">
                    <AntdComponents.Form requiredMark={false}>
                      <AntdComponents.Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'name is required'
                          }
                        ]}
                      >
                        <AntdComponents.Input placeholder='Name*' className='landing_input' />
                      </AntdComponents.Form.Item>
                      <AntdComponents.Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'email is required'
                          },
                          {
                            type: 'email',
                            message: 'Enter valid email!'
                          }
                        ]}
                      >
                        <AntdComponents.Input placeholder='Email*' className='landing_input' />
                      </AntdComponents.Form.Item>
                      <AntdComponents.Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'email is required'
                          },
                          {
                            type: 'email',
                            message: 'Enter valid email!'
                          }
                        ]}
                      >
                        <AntdComponents.Input.TextArea rows={6} placeholder='Email*' className='landing_input' />
                      </AntdComponents.Form.Item>
                      <AntdComponents.Button type='ghost' htmlType='submit' className="landing_button w-full md:w-56 mt-5">
                        Send
                      </AntdComponents.Button>
                    </AntdComponents.Form>
                  </div>
                </div>
              </AntdComponents.Col>
            </AntdComponents.Row>
          </div>
        </section>
        {/* footer */}
        <div className="bg-footer_back">
          <AntdComponents.Typography className='text-center text-White font-normal paragraphSmall py-3'>&copy; {date.getFullYear()} - All Rights Reserved - <span className='underline font-bold'>Vault PD</span> | <span>Designed & Developed by <span className='font-bold'>Solid App Maker</span></span> </AntdComponents.Typography>
        </div>
      </div>
    </div>
  );
};

export default LandingMain;
