export const RouterKeys = {
    Auth: {
        CONTINUE: '/',
        REGISTER_LOGIN: 'register_login',
        REGISTER: 'register_user',
        LOGIN: 'login_user',
        CREATE_PASSWORD: 'create_password',
        FORGOT_PASSWORD: 'forgot_password',
        OTP_VERIFY: 'otp-verify',
        MAX_ATTEMPT: 'max-attempt',
        ACCOUNT_DELETE: 'account-delete-page-mobile',
        LINKEDIN: 'linkedin',
    },
    NON_Auth: {
        ONBOARDING: {
            WELCOME: 'onboarding-welcome',
            AREA_PROTECT: 'onboarding-area-protect',
            WEATHER: 'onboarding-weather',
            HOSPITAL: 'onboarding-hospital',
            THANK_YOU: 'onboarding-thank-you',
        },
        HOME: {
            HOME: '/home',
            FOLDER_DOCUMENTS: '/folder_documents',
            MOVE_FOLDERS: '/move_folders',
            DOCUMENTS_VIEW: '/documents_view',
            CROP_VIEW: '/crop_image',
            UPLOAD_STATUS: '/upload-status-page',
        },
        SCAN: {
            SCAN: '/scan'
        },
        TUTORIALS: {
            TUTORIAL_TRAINING: '/training',
            DOCUMENT_CHECKLIST: '/document-checklist',
            DOCUMENT_CHECKLIST_KEYS: '/document-checklist-keys',
            DOCUMENT_CHECKLIST_KEY_PAGE: '/document-checklist-key-page',
            SHARE_DOCUMENT: '/share-document',
            ADD_PERSON: '/add-person',
        },
        GENERAL: {
            TERMS: '/terms',
            Privacy: '/privacy-policy',
            SUPPORT_FEEDBACK: '/support-feedback',
            FEEDBACK: '/feedback',
            NOTIFICATION: '/notification',
        },
        TRASH: {
            TRASH: '/trash'
        },
        PROFILE: {
            PROFILE:'/profile'
        },
        ALERTS:{
            ALERTS:'/alerts'
        },
        CONTACTS:{
            CONTACTS:'/contacts',
            ADD_CONTACTS:'/add-contacts',
            VIEW_ACCESS:'/view-access-level',
        },
        SECURITY:{
            SECURITY:'/security'
        },
        SUBSCRIPTION:{
            SUBSCRIPTION:'/subscriptions',
            ALL_PLANS:'/all_plans'
        }
    },
}