import React, { useRef } from 'react'
import * as AntdComponents from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactIcons } from '../utils/ReactIcons'
import { base64ToFile, fileUrl } from '../utils/Constant'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { QueryKeys } from '../utils/RTKKeys'
import { AUTH } from '../utils/Endpoints'
import CustomToast from '../utils/CustomToast'
import { useDynamicMutationMutation } from '../redux/service/apiSlice'
const CropImage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const cropperRef = useRef();
    const fileData = location.state?.fileData
    console.log('stateData', `${fileUrl}${fileData?.document_url}`);

    const [updateDocument,{isLoading}] = useDynamicMutationMutation();

    // crop and update api
    const getCropData = async () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            let cropImg = cropperRef.current?.cropper.getCroppedCanvas().toDataURL('image/png')
            // setCroppedImage(cropImg)
            let file = base64ToFile(cropImg, `modified_image`)
            let formData = new FormData()
            formData.append('document_id', fileData?._id)
            formData.append('documents', file)

            let requestData = {
                endpoint: AUTH.HOME.DOCUMENT.DOCUMENT_UPDATE,
                method: 'PUT',
                body: formData,
                key: [QueryKeys.DOCUMENT_DETAIL, QueryKeys.FOLDER_DETAIL]
            };

            try {
                const { data, error } = await updateDocument(requestData);
                if (data) {
                    // CustomToast('s', data?.message);
                    navigate(-1)
                } else {
                    CustomToast('e', error?.data?.message || 'Something went wrong');
                }
            } catch (err) {
                CustomToast('e', 'An unexpected error occurred.');
            }
        }
    };
    return (
        <AntdComponents.Spin spinning={isLoading}>
            <AntdComponents.Row className='mt-3'>
                <AntdComponents.Col xs={24} sm={24} md={22} lg={12} xl={12}>
                    <div className='bg-GreyLight3 rounded-10 p-4'>
                        <div className="flex items-center justify-between">
                            <ReactIcons.BackIcon onClick={() => navigate(-1)} />
                            <AntdComponents.Typography className="paragraph text_truncate md:max-w-200 max-w-66">Edit Photo</AntdComponents.Typography>
                            <ReactIcons.RightCheckIcon size={30} onClick={() => getCropData()} />
                        </div>
                        <div className="mt-3">
                            <Cropper
                                ref={cropperRef}
                                style={{ height: 600, width: "100%" }}
                                zoomTo={0}
                                initialAspectRatio={16 / 9}
                                preview=".img-preview"
                                src={`${fileUrl}${fileData?.document_url}`}
                                viewMode={1}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false}
                                guides={true}
                            />
                        </div>
                        {/* <div className="bg-no-repeat bg-cover bg-center mt-4" style={{ backgroundImage: `url(${fileUrl}${fileData?.document_url})`, height: 600 }}></div> */}
                    </div>
                </AntdComponents.Col>
            </AntdComponents.Row>
        </AntdComponents.Spin>
    )
}

export default CropImage
