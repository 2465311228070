import React from 'react'
import * as AntdComponents from 'antd'
import { checklistArr } from '../../../../utils/Constant'
import { useNavigate } from 'react-router-dom'
import { ReactIcons } from '../../../../utils/ReactIcons'
import { useSearchParams } from 'react-router-dom/dist'
const CheckListKeys = () => {
    const navigate = useNavigate()
    const [searchParam] = useSearchParams()
    let queryData = searchParam.get('backPage')

    return (
        <div className='content_common'>
            {
                queryData &&
                <div className="">
                    <ReactIcons.BackIcon onClick={() => { navigate(-1) }} />
                </div>
            }
            <div className="flex items-center mt-10 flex-col min-h-700">
                <AntdComponents.Typography className='titleMedium text-center'>Discover key documents</AntdComponents.Typography>
                <AntdComponents.Typography className='paragraph text-center'>Each area contains ideas for what types of documents<br /> to upload to protect what matters to you</AntdComponents.Typography>
                <div className="mt-7 w-full md:w-[800px] ">
                    <AntdComponents.Row gutter={[30, 30]}>
                        {
                            checklistArr?.map((item, index) => {
                                return (
                                    <AntdComponents.Col xs={24} sm={24} md={12} lg={12} xl={12} key={index} onClick={() => { navigate(item?.link) }}>
                                        <AntdComponents.Button type='ghost' className={`w-full h-16 bg-GreyLight p-3 border-2 rounded-5 border-Purple titleSmall font-[500]`} onClick={() => { }}>{item?.title}</AntdComponents.Button>
                                    </AntdComponents.Col>
                                )
                            })
                        }
                    </AntdComponents.Row>
                </div>
            </div>

        </div>
    )
}

export default CheckListKeys
