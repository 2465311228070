import React from 'react'
import * as AntdComponents from 'antd'
import { useDynamicMutationMutation, useDynamicQueryQuery } from '../../../redux/service/apiSlice'
import { AUTH } from '../../../utils/Endpoints'
import { QueryKeys } from '../../../utils/RTKKeys'
import { fileNameAndUrl } from '../../../utils/Constant'
import CardCommon from '../../../components/CardCommon'
import moment from 'moment/moment'
import CustomToast from '../../../utils/CustomToast'
import { useNavigate, useSearchParams } from 'react-router-dom/dist'

const FoldersForMove = () => {
    const navigate = useNavigate()
    const [folderList, setFolderList] = React.useState([])
    const [searchParam] = useSearchParams()
    const [moveFileMutation] = useDynamicMutationMutation();
    // get  listing
    const { data: listData } = useDynamicQueryQuery({
        endpoint: AUTH.HOME.FOLDER.GET_FOLDER_LIST,
        params: {},
        key: QueryKeys.FOLDER_LIST
    }, { refetchOnMountOrArgChange: true })

    React.useEffect(() => {
        if (listData?.data) {
            let filterArr = listData?.data?.filter((ele) => ele?.['folder_name'])
            setFolderList(filterArr)
        }
    }, [listData])

    const moveFile = async (folderId) => {
        let getFileId = searchParam.get('file_id')
        let payload = {
            "folder_id": folderId,
            "document_id": [
                getFileId
            ]
        }
        let requestData = {
            endpoint: AUTH.HOME.DOCUMENT.DOCUMENT_MOVE,
            method: 'PUT',
            body: payload,
        };

        try {
            const { data, error } = await moveFileMutation(requestData);
            if (data) {
                navigate(-1)
                CustomToast('s', data?.message || 'Documents moved successfully');
            } else {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    }
    return (
        <div>
            <AntdComponents.Row gutter={[20, 20]}>
                {
                    folderList?.map((item, index) => {
                        let { name, url } = fileNameAndUrl(item)
                        return (
                            <AntdComponents.Col xs={24} sm={24} md={12} lg={8} xl={8} key={index} className='cursor-pointer' onClick={() => moveFile(item?._id)}>
                                <CardCommon data={item} image={url} size={50} shape={'square'} name={name} otherContent={moment(item?.createdAt).format('lll')}
                                    byDetailPage={item['document_name'] ? false : true}  docUploading={true}/>
                            </AntdComponents.Col>
                        )
                    })
                }
            </AntdComponents.Row>
        </div>
    )
}

export default FoldersForMove
