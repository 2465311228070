import React from 'react';
import * as AntdComponents from 'antd';
import { landingImages } from './landingImages';
import { RouterKeys } from '../../Routes/RouterKeys';
import { useNavigate } from 'react-router-dom/dist';
import { ReactIcons } from '../../utils/ReactIcons';

const Header = () => {
  const navigate = useNavigate()
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [active, setActive] = React.useState("#home");
  return (
    <>
      <div className="landing_header flex items-center justify-between px-5">
        <div>
          <AntdComponents.Image src={landingImages.LOGO} height={35} preview={false} />
        </div>
        {/* <div className="hidden lg:flex gap-12 xl:gap-14">
          <a href="#home" className="landing_menus">Home</a>
          <a href="#about" className="landing_menus ">About Us</a>
          <a href="#features" className="landing_menus ">Features</a>
          <a href="#download" className="landing_menus ">Download</a>
          <a href="#contact_su" className="landing_menus ">Contact Us</a>
        </div> */}
        <nav className='hidden lg:flex gap-12 xl:gap-14'>
          {[
            { id: "#home", label: "Home" },
            { id: "#about", label: "About Us" },
            { id: "#features", label: "Features" },
            { id: "#download", label: "Download" },
            { id: "#contact_su", label: "Contact Us" },
          ].map((menu) => (
            <a
              key={menu.id}
              href={menu.id}
              className={`landing_menus ${active === menu.id ? "landing_menus_active" : ""}`}
              onClick={() => setActive(menu.id)}
            >
              {menu.label}
            </a>
          ))}
        </nav>
        <div className='hidden lg:block'>
          <AntdComponents.Button type='ghost' className="landing_button" onClick={() => navigate(RouterKeys.Auth.LOGIN)}>Login / Sign Up</AntdComponents.Button>
        </div>
        {/* hamburger */}
        <div className="block lg:hidden">
          <ReactIcons.HamBurgerIcon size='40' className={'text-DarkBlue'} onClick={() => setOpenDrawer(true)} />
        </div>
      </div>
      {/* drawer */}
      <AntdComponents.Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      // closeIcon={false}
      >
        <div className="flex flex-col gap-8 xl:gap-14">
          <a href="#home" className="landing_menus paragraph">Home</a>
          <a href="#about" className="landing_menus paragraph">About Us</a>
          <a href="#features" className="landing_menus paragraph">Features</a>
          <a href="#download" className="landing_menus paragraph">Download</a>
          <a href="#contact_su" className="landing_menus paragraph">Contact Us</a>
        </div>
        <div className="mt-5">
          <AntdComponents.Button type='ghost' className="landing_button" onClick={() => navigate(RouterKeys.Auth.LOGIN)}>Login / Sign Up</AntdComponents.Button>
        </div>
      </AntdComponents.Drawer>
    </>
  );
};

export default Header;
