import React from 'react'
import { ReactIcons } from '../../utils/ReactIcons'
import * as AntdComponents from 'antd'
import { STATIC_IMAGES } from '../../utils/StaticImages'
import { RouterKeys } from '../../Routes/RouterKeys'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import OTPInput from 'react-otp-input'
import { useMutation } from '../../redux/hook/useApi'
import { NON_AUTH } from '../../utils/Endpoints'
import CustomToast from '../../utils/CustomToast'
import { useDispatch, useSelector } from 'react-redux'
import CustomButton from '../../components/CustomButton'
import { AuthData } from '../../redux/slices/authSlice'
const OTPVerify = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const userData = location?.state?.authData

  const email = useSelector((state) => state?.auth?.email)
  const navigate = useNavigate()
  const [searchParam] = useSearchParams()
  const [pageType, setPageType] = React.useState('');
  const { handleMutation, isLoading } = useMutation();
  const { handleMutation: resendFunction, isLoading: resendLoading } = useMutation();
  const [identity, setIdentity] = React.useState('');
  const [otp, setOtp] = React.useState('');
console.log('userData?.phone_number',userData?.phone_number===null);

  React.useEffect(() => {
    setPageType(searchParam.get('page-type'))
  }, [])

  const reSendOtp = async() => {
    let payload = {
      "country_code": userData?.country_code,
      "phone_number": userData?.phone_number?userData?.phone_number:'',
      "email": userData?.email
    }

    const { data, error } = await resendFunction({
      endpoint: NON_AUTH.RESENT_OTP,
      method: 'POST',
      body: payload,
    });
    if (data) {
      CustomToast('s', data?.message)
    } else {
      CustomToast('e', error?.data?.message)
    }
  }

  const handleSendOtp = async () => {
    let payload = {
      "otp": otp,
      "email": email,
      'phone_number':userData?.phone_number?userData?.phone_number:''
    }

    const { data, error } = await handleMutation({
      endpoint: NON_AUTH.VERIFY_OTP,
      method: 'POST',
      body: payload,
    });
    if (data) {
      CustomToast('s', data?.message)
      setIdentity(true)
      setTimeout(() => {
        setIdentity(false)
        if (pageType == 'forgot') {
          navigate(`/${RouterKeys.Auth.CREATE_PASSWORD}?page-type=forgot`, { replace: true })
        } else if (pageType == 'login_two_factor') {
          navigate(`${RouterKeys.NON_Auth.HOME}`)
          dispatch(AuthData(userData));
        } else {
          dispatch(AuthData(userData));
          navigate(`/${RouterKeys.NON_Auth.ONBOARDING.WELCOME}`, { replace: true })
        }
      }, 2000)
    } else {
      CustomToast('e', error?.data?.message)
    }
  }

  return (
    <AntdComponents.Spin spinning={resendLoading}>
      <div className='auth-main-div'>
        <div className='auth-form rounded-10 shadow-formShadow p-4'>
          <div className="back-box">
            <ReactIcons.BackIcon style={{ fontSize: 35, color: 'var(--defaultPurple)', cursor: 'pointer' }} onClick={() => navigate(`/${RouterKeys.Auth.LOGIN}`)} />
            <AntdComponents.Typography className='titleMedium text-center'>Verify your identify</AntdComponents.Typography>
            <AntdComponents.Typography className=''></AntdComponents.Typography>
          </div>
          <div className="text-center mt-6">
            <AntdComponents.Image src={STATIC_IMAGES.COMMON.Lock} preview={false} height={100} />
            {
              !identity ?
                <>
                  <AntdComponents.Typography className='titleSmall mt-4'>Enter the 6 digit code</AntdComponents.Typography>
                  <AntdComponents.Typography className='paragraph mt-4'>The code was sent to the <span className='font-bold'>email address</span> {email}</AntdComponents.Typography>
                  <AntdComponents.Typography className='paragraph mt-4'>This code expires within 15 minutes of receiving it<br />via text message.</AntdComponents.Typography>
                  <div className="mt-8">
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      placeholder="****"
                      containerStyle="otp_container"
                      inputStyle="otp_input_container"
                      shouldAutoFocus={true}
                      renderInput={(props) => <input {...props} />}
                    />
                    <CustomButton title='Confirm' className='mt-5 mb-2' isLoading={isLoading} onClick={() => handleSendOtp()} />
                    <AntdComponents.Typography className='text-TermsColor text-description font-semibold cursor-pointer' onClick={() => reSendOtp()}>Resend new code</AntdComponents.Typography>
                  </div>
                </>
                :
                <div>
                  <AntdComponents.Typography className='titleSmall mt-3'>Success</AntdComponents.Typography>
                  <AntdComponents.Typography className='paragraph mt-3 mb-10'>Your 2 Factor Authentication was successfully<br /> completed. Your account is now extra secure.</AntdComponents.Typography>
                </div>
            }
          </div>
        </div>

      </div>
    </AntdComponents.Spin>
  )
}

export default OTPVerify
