import React from 'react'
import LoaderTheme from '../../../components/LoaderTheme'
import * as AntdComponents from 'antd'
import CardCommon from '../../../components/CardCommon'
import { ReactIcons } from '../../../utils/ReactIcons'
// import LoaderConfetti from '../../../components/LoaderConfetti'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import CommonDocumentDetails from '../../../components/CommonDocumentDetails'


const popoverArr = [
    {
        title: 'Rename File',
        icon: STATIC_IMAGES.EDIT,
        type: 'edit',
    },
]
const UploadStatus = (
    {
        folderCreateLoading = false,
        docUploading,
        abortControllerRef,
        setCompleteStatus,
        docUploadSuccess,
        uploadingImages,
        uploadedImages
    }) => {
    const [finalUploadFiles, setFinalUploadFiles] = React.useState([])
    const [uploadingImagesArr, setUploadingImagesArr] = React.useState(uploadingImages) || []

    React.useEffect(() => {
        setFinalUploadFiles(uploadedImages)
    }, [uploadedImages])


    // uploading percentage
    React.useEffect(() => {
        let interval;
        let number = 0
        if (!docUploadSuccess && docUploading) {
            interval = setInterval(() => {
                setUploadingImagesArr((prevUploadingImages) =>
                    prevUploadingImages.map((item) => {
                        number = Math.min(number + 2, docUploadSuccess ? 100 : 98); // Cap at 98 until docUploadSuccess is true
                        return ({
                            ...item,
                            status: number
                        })
                    }
                    )
                );
            }, 2500);
        }

        // Clear the interval when both conditions are met
        if (docUploadSuccess || !docUploading) {
            clearInterval(interval);
            let mapArr = uploadingImages?.map((item) => {
                return {
                    name: item?.name,
                    url: item?.url
                }
            })
            setUploadingImagesArr(mapArr)
        }

        // Cleanup the interval on component unmount
        return () => clearInterval(interval);
    }, [folderCreateLoading, docUploading]);


    const handleClose = () => {
        setCompleteStatus(false)
        if (abortControllerRef.current) {
            abortControllerRef.current.abort(); // Abort the request
        }
    }
    return (
        <>
            <div className='text-center bg-GreyLight3 min-h-700 rounded-10 p-3'>
                <div className="flex w-[100%] justify-end">
                    <ReactIcons.CloseIcon onClick={() => handleClose()} />
                </div>
                {
                    (docUploading && !docUploadSuccess) ?
                        <>
                            <LoaderTheme />
                            <AntdComponents.Typography className='titleMedium'>Uploading</AntdComponents.Typography>
                            <AntdComponents.Typography className='paragraph'>Adding to your drive in progress</AntdComponents.Typography>
                            <div className="my-8 w-full md:w-1/2 m-auto">
                                <AntdComponents.Row gutter={[20, 20]}>
                                    {
                                        uploadingImagesArr?.map((item, index) => {
                                            let type = item?.type.split('/')
                                            let url = ''
                                            if (type?.[0] === 'image' && item?.url != '') {
                                                url = item?.url
                                            } else {
                                                url = STATIC_IMAGES.FILE
                                            }
                                            return (
                                                <AntdComponents.Col xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
                                                    <CardCommon image={url} size={60} shape={'square'} name={item?.name} status={item?.status ? `${item?.status}%` : ''} cardPadding='p-2' docUploading={docUploading} byDetailPage={true} />
                                                </AntdComponents.Col>
                                            )
                                        })
                                    }
                                </AntdComponents.Row>
                            </div>
                        </>
                        :
                        <>
                            {/* <div className="confetti w-36 h-36 relative mx-auto my-8 ">
                                <LoaderConfetti />
                            </div> */}
                            <AntdComponents.Typography className='titleMedium'>Upload Complete</AntdComponents.Typography>
                            <AntdComponents.Typography className='paragraph'>It is now available in your drive.</AntdComponents.Typography>
                            <div className="my-8 w-full md:w-1/2 m-auto">
                                <CommonDocumentDetails data={finalUploadFiles} setFinalUploadFiles={setFinalUploadFiles} popoverArr={popoverArr} folderId={uploadedImages?.[0]?.folder_id} colNumber={false} byDetailPage={false} />
                            </div>
                        </>
                }
            </div>
        </>
    )
}

export default UploadStatus
