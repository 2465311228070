import React from 'react'
import { STATIC_IMAGES } from '../../utils/StaticImages'
import * as AntdComponents from 'antd'
import CustomToast from '../../utils/CustomToast'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '../../redux/hook/useApi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { NON_AUTH } from '../../utils/Endpoints'
import { RouterKeys } from '../../Routes/RouterKeys'
import CommonHeader from './CommonHeader'
import CustomButton from '../../components/CustomButton'
import { DeviceIdStore, EmailStore } from '../../redux/slices/authSlice'
import { DeviceUUID } from 'device-uuid';

const CreatePassword = () => {
    const deviceId = new DeviceUUID().get();
    const dispatch = useDispatch()
    const email = useSelector((state) => state?.auth?.email)
    const navigate = useNavigate()
    const [searchParam] = useSearchParams()
    const [pageType, setPageType] = React.useState('');
    const { handleMutation, isLoading } = useMutation();
    const registerData = useSelector((state) => state.auth.user_register_data)
    console.log("registerData", registerData);

    const [form] = AntdComponents.Form.useForm()

    // pageType for reset password and create password
    React.useEffect(() => {
        setPageType(searchParam.get('page-type'))
    }, [])

    const validatePassword = (password) => {
        if (typeof password !== 'string') {
            return {
                length: false,
                hasSymbol: false,
                hasNumber: false,
            };
        }
        return {
            length: password.length >= 8, // Check string length
            hasSymbol: /[!@#$%^&*]/.test(password), // Check for symbols
            hasNumber: /\d/.test(password), // Check for numbers
        };
    };
    const password = AntdComponents.Form.useWatch('create_password', form);
    const confirmPassword = AntdComponents.Form.useWatch('confirm_password', form);

    const passwordValidation = validatePassword(password || '');
    const passwordsMatch = password && confirmPassword && password === confirmPassword;

    const handleSubmit = async (values) => {
        if (values.create_password === values.confirm_password) {
            let userName = registerData?.first_name?.trim() + ' ' + registerData?.last_name?.trim()
            let payload = {}
            if (pageType == 'forgot') {
                payload = {
                    "new_password": values.confirm_password,
                    "email": email
                }
            } else {
                payload = {
                    "os_type": "web",
                    "device_country": "US",
                    "device_name": "web",
                    "device_id": deviceId,
                    "password": values.confirm_password,
                    "email": registerData.email,
                    "user_name": userName
                }
            }
            const { data, error } = await handleMutation({
                endpoint: pageType == 'forgot' ? NON_AUTH.RESET_PASSWORD : NON_AUTH?.REGISTER_USER,
                method: 'POST',
                body: payload,
            });
            if (data) {
                if (pageType == 'forgot') {
                    navigate(`/${RouterKeys.Auth.LOGIN}`);
                } else {
                    dispatch(DeviceIdStore(deviceId))
                    dispatch(EmailStore(registerData.email.trim()));
                    navigate(`/${RouterKeys.Auth.OTP_VERIFY}`, { state: { authData: data?.data } });
                }
                CustomToast('s', data?.message)
            } else {
                CustomToast('e', error?.data?.message || 'Register failed!')
            }
        } else {
            CustomToast('e', 'Password not matched!')
        }
    }
    return (
        <div className='auth-main-div'>
            <CommonHeader />
            <div className='text-center auth-form rounded-10 shadow-formShadow p-4'>
                <div className="inputs-container">
                    <AntdComponents.Typography className='titleMedium text-center mb-4'>{pageType == 'forgot' ? 'Reset your password' : 'Create a Password'}</AntdComponents.Typography>
                    <AntdComponents.Form form={form} name="Auth" initialValues={{ remember: true, }} onFinish={handleSubmit} requiredMark={false} className='mt-2' layout='vertical'>
                        <AntdComponents.Form.Item name="create_password" autoComplete="off" className='mb-0'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter password!',
                                },
                            ]}>
                            <AntdComponents.Input.Password className='input-box' placeholder='Create a password' prefix={<AntdComponents.Avatar src={STATIC_IMAGES.FORM.KEY} size={35} shape='square' />} iconRender={(visible) => (visible ? <AntdComponents.Avatar role='button' src={STATIC_IMAGES.FORM.EYE_ONN} size={40} shape='square' /> : <AntdComponents.Avatar role='button' src={STATIC_IMAGES.FORM.EYE_OFF} size={40} shape='square' />)} />
                        </AntdComponents.Form.Item>
                        {/* validations */}
                        <div className="my-4">
                            <div className="flex items-center gap-3 mt-3">
                                <AntdComponents.Avatar
                                    src={passwordValidation.length ? STATIC_IMAGES.FORM.RIGHT_TICK : STATIC_IMAGES.FORM.WARN_BOX_FORM}
                                    preview={false}
                                    size={35} shape='square'
                                />
                                <AntdComponents.Typography className="para">8+ Characters</AntdComponents.Typography>
                            </div>
                            <div className="flex items-center gap-3 mt-3">
                                <AntdComponents.Avatar
                                    src={passwordValidation.hasSymbol ? STATIC_IMAGES.FORM.RIGHT_TICK : STATIC_IMAGES.FORM.WARN_BOX_FORM}
                                    preview={false}
                                    size={35} shape='square'
                                />
                                <AntdComponents.Typography className="para">Symbol Used (!@#$%^&*)</AntdComponents.Typography>
                            </div>
                            <div className="flex items-center gap-3 mt-3">
                                <AntdComponents.Avatar
                                    src={passwordValidation.hasNumber ? STATIC_IMAGES.FORM.RIGHT_TICK : STATIC_IMAGES.FORM.WARN_BOX_FORM}
                                    preview={false}
                                    size={35} shape='square'
                                />
                                <AntdComponents.Typography className="para">Number used</AntdComponents.Typography>
                            </div>
                        </div>
                        <AntdComponents.Form.Item name="confirm_password" autoComplete="off"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter confirm password!',
                                },
                            ]}>
                            <AntdComponents.Input.Password className='input-box' placeholder='confirm new password' prefix={<AntdComponents.Avatar src={STATIC_IMAGES.FORM.KEY} size={30} shape='square' />} iconRender={(visible) => (visible ? <AntdComponents.Avatar role='button' src={STATIC_IMAGES.FORM.EYE_ONN} size={40} shape='square' /> : <AntdComponents.Avatar role='button' src={STATIC_IMAGES.FORM.EYE_OFF} size={40} shape='square' />)} />
                        </AntdComponents.Form.Item>
                        {/* Password Match Validation */}
                        <div className="flex items-center gap-3 mt-3">
                            <AntdComponents.Avatar
                                src={passwordsMatch ? STATIC_IMAGES.FORM.RIGHT_TICK : STATIC_IMAGES.FORM.WARN_BOX_FORM}
                                preview={false}
                                size={35} shape='square'
                            />
                            <AntdComponents.Typography className="para">Password matches</AntdComponents.Typography>
                        </div>
                        <CustomButton title='Set Password' className='mt-10 mb-4' isLoading={isLoading} htmlType='submit' />
                    </AntdComponents.Form>
                </div>
            </div>
        </div>
    )
}

export default CreatePassword
