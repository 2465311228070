// import Continue from "../getStartedPages/Continue";
import RegisterAndLogin from "../getStartedPages/RegisterAndLogin";
import NonAuthLayout from "../layouts/NonAuthLayout";
import CreatePassword from "../screen/Auth/CreatePassword";
import ForgotPassword from "../screen/Auth/ForgotPassword";
import Login from "../screen/Auth/Login";
import Register from "../screen/Auth/Register";
import OTPVerify from "../screen/Auth/OTPVerify";
import { RouterKeys } from "./RouterKeys";
import MaxAttempt from "../screen/Auth/MaxAttempt";
import OnBoardingLayout from "../layouts/OnBoardingLayout";
import Welcome from "../screen/NonAuth/Onboarding/Welcome";
import ProtectRoute from "./ProtectRoute";
import AuthProtect from "./AuthProtect";
import AreaProtect from "../screen/NonAuth/Onboarding/AreaProtect";
import Weather from "../screen/NonAuth/Onboarding/Weather";
import Hospital from "../screen/NonAuth/Onboarding/Hospital";
import ThankYou from "../screen/NonAuth/Onboarding/ThankYou";
import MainLayout from "../layouts/MainLayout";
import Scan from "../screen/NonAuth/Scan/Scan";
import Training from "../screen/NonAuth/Tutorials/Training";
import ShareDocument from "../screen/NonAuth/Tutorials/ShareDocument";
import AddAPerson from "../screen/NonAuth/Tutorials/AddAPerson";
import CheckList from "../screen/NonAuth/Tutorials/checklist/CheckList";
import UploadStatus from "../screen/NonAuth/Home/UploadStatus";
import FolderDocuments from "../screen/NonAuth/Home/FolderDocuments";
import CheckListKeys from "../screen/NonAuth/Tutorials/checklist/CheckListKeys";
import KeyDetail from "../screen/NonAuth/Tutorials/checklist/KeyDetail";
import LinkedInComp from "../screen/Auth/LinkedInComp";
import CommonDocView from "../components/CommonDocView";
import Terms from "../screen/NonAuth/General/Terms";
import Privacy from "../screen/NonAuth/General/Privacy";
import Trash from "../screen/NonAuth/Trash/Trash";
import SupportAndFeedback from "../screen/NonAuth/General/SupportAndFeedback";
import ProfileComp from "../screen/NonAuth/Profile/ProfileComp";
import Alerts from "../screen/NonAuth/Alerts/Alerts";
import Contacts from "../screen/NonAuth/Contacts/Contacts";
import AddContact from "../screen/NonAuth/Contacts/AddContact";
import ViewAccessLevel from "../screen/NonAuth/Contacts/ViewAccessLevel";
import Notification from "../screen/NonAuth/Notification/Notification";
import Security from "../screen/NonAuth/Security/Security";
import Subscription from "../screen/NonAuth/Subscription/Subscription";
import AllPlans from "../screen/NonAuth/Subscription/AllPlans";
import Home from "../screen/NonAuth/Home/Home";
import LandingMain from "../getStartedPages/landing/LandingMain";
import Feedback from "../screen/NonAuth/General/Feedback";
import FoldersForMove from "../screen/NonAuth/Home/FoldersForMove";
import CropImage from "../components/CropImage";
// import UploadStatus from "../screen/Auth/Home/UploadStatus";

export const AppRouter = [
    // // landing
    // {
    //     path: RouterKeys.Auth.CONTINUE,
    //     children: [
    //         {
    //             index: true, // Default route when visiting '/'
    //             element: <LandingMain/>,
    //         },
    //     ]
    // },
    // Auth Part
    {
        element: (
            <AuthProtect>
                <NonAuthLayout />,
            </AuthProtect>
        ),
        children: [
            {
                index: true, // Default route when visiting '/'
                element: <LandingMain/>,
            },
            {
                path: RouterKeys.Auth.REGISTER_LOGIN,
                element: <RegisterAndLogin />,
            },
            {
                path: RouterKeys.Auth.REGISTER,
                element: <Register />,
            },
            {
                path: RouterKeys.Auth.CREATE_PASSWORD,
                element: <CreatePassword />,
            },
            {
                path: RouterKeys.Auth.LOGIN,
                element: <Login />,
            },
            {
                path: RouterKeys.Auth.FORGOT_PASSWORD,
                element: <ForgotPassword />,
            },
            {
                path: RouterKeys.Auth.OTP_VERIFY,
                element: <OTPVerify />,
            },
            {
                path: RouterKeys.Auth.MAX_ATTEMPT,
                element: <MaxAttempt />,
            },
            {
                path: RouterKeys.Auth.LINKEDIN,
                element: <LinkedInComp />,
            },
        ],
    },
    // onboarding part
    {
        element: (
            <ProtectRoute>
                <OnBoardingLayout />
            </ProtectRoute>
        ),
        children: [
            {
                path: RouterKeys.NON_Auth.ONBOARDING.WELCOME,
                element: <Welcome />,
            },
            {
                path: RouterKeys.NON_Auth.ONBOARDING.AREA_PROTECT,
                element: <AreaProtect />,
            },
            {
                path: RouterKeys.NON_Auth.ONBOARDING.WEATHER,
                element: <Weather />,
            },
            {
                path: RouterKeys.NON_Auth.ONBOARDING.HOSPITAL,
                element: <Hospital />,
            },
            {
                path: RouterKeys.NON_Auth.ONBOARDING.THANK_YOU,
                element: <ThankYou />,
            },
        ],
    },
    // after login parts
    {
        element: (
            <ProtectRoute>
                <MainLayout />
            </ProtectRoute>
        ),
        children: [
            {
                path: RouterKeys.NON_Auth.HOME.HOME,
                element: <Home/>,
            },
            {
                path: RouterKeys.NON_Auth.HOME.FOLDER_DOCUMENTS,
                element: <FolderDocuments />,
            },
            {
                path: RouterKeys.NON_Auth.HOME.MOVE_FOLDERS,
                element: <FoldersForMove />,
            },
            {
                path: RouterKeys.NON_Auth.HOME.DOCUMENTS_VIEW,
                element: <CommonDocView />,
            },
            {
                path: RouterKeys.NON_Auth.HOME.CROP_VIEW,
                element: <CropImage />,
            },
            {
                path: RouterKeys.NON_Auth.HOME.UPLOAD_STATUS,
                element: <UploadStatus />,
            },
            {
                path: RouterKeys.NON_Auth.SCAN.SCAN,
                element: <Scan />,
            },
            {
                path: RouterKeys.NON_Auth.TUTORIALS.TUTORIAL_TRAINING,
                element: <Training />,
            },
            {
                path: RouterKeys.NON_Auth.TUTORIALS.ADD_PERSON,
                element: <AddAPerson />,
            },
            {
                path: RouterKeys.NON_Auth.TUTORIALS.SHARE_DOCUMENT,
                element: <ShareDocument />,
            },
            {
                path: RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST,
                element: <CheckList />,
            },
            {
                path: RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEYS,
                element: <CheckListKeys />,
            },
            {
                path: RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST_KEY_PAGE,
                element: <KeyDetail />,
            },
            {
                path: RouterKeys.NON_Auth.TRASH.TRASH,
                element: <Trash />,
            },
            {
                path: RouterKeys.NON_Auth.ALERTS.ALERTS,
                element: <Alerts />,
            },
            {
                path: RouterKeys.NON_Auth.GENERAL.TERMS,
                element: <Terms />,
            },
            {
                path: RouterKeys.NON_Auth.CONTACTS.CONTACTS,
                element: <Contacts />,
            },
            {
                path: RouterKeys.NON_Auth.CONTACTS.ADD_CONTACTS,
                element: <AddContact />,
            },
            {
                path: RouterKeys.NON_Auth.CONTACTS.VIEW_ACCESS,
                element: <ViewAccessLevel />,
            },
            {
                path: RouterKeys.NON_Auth.GENERAL.Privacy,
                element: <Privacy />,
            },
            {
                path: RouterKeys.NON_Auth.GENERAL.NOTIFICATION,
                element: <Notification />,
            },
            {
                path: RouterKeys.NON_Auth.GENERAL.SUPPORT_FEEDBACK,
                element: <SupportAndFeedback />,
            },
            {
                path: RouterKeys.NON_Auth.GENERAL.FEEDBACK,
                element: <Feedback />,
            },
            {
                path: RouterKeys.NON_Auth.SECURITY.SECURITY,
                element: <Security/>,
            },
            {
                path: RouterKeys.NON_Auth.PROFILE.PROFILE,
                element: <ProfileComp />,
            },
            {
                path:RouterKeys.NON_Auth.SUBSCRIPTION.SUBSCRIPTION,
                element: <Subscription />,
            },
            {
                path:RouterKeys.NON_Auth.SUBSCRIPTION.ALL_PLANS,
                element: <AllPlans />,
            },
        ],
    },
];

