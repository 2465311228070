import React from 'react'
import { ReactIcons } from '../../../utils/ReactIcons'
import * as AntdComponents from 'antd'
import CustomButton from '../../../components/CustomButton'
import { AUTH } from '../../../utils/Endpoints'
import { QueryKeys } from '../../../utils/RTKKeys'
import CustomToast from '../../../utils/CustomToast'
const CreateFolder = ({ folderModalOpen, SetFolderModalOpen, createFolderFunc,folderCreateLoading }) => {
    const [folderName, setFolderName] = React.useState('')
    const [errorMsg, setErrorMsg] = React.useState(false)
    const folderNameHandle = (e) => {
        let value = e.target.value
        setFolderName(value)
        if (value) {
            setErrorMsg(false)
        } else {
            setErrorMsg(true)
        }
    }

    const folderError = () => {
        if (folderName.trim().length != 0) {
            setErrorMsg(false)
            createFolder()
        } else {
            setErrorMsg(true)
        }
    }
    const createFolder = async (files) => {
        let payload = {
            folder_name: folderName.trim(),
        };

        let requestData = {
            endpoint: AUTH.HOME.FOLDER.FOLDER_CREATE,
            method: 'POST',
            body: payload,
            key: QueryKeys.FOLDER_LIST
        };

        try {
            const { data, error } = await createFolderFunc(requestData);
            if (data) {
                setFolderName('')
                SetFolderModalOpen(false)
                CustomToast('s', data?.message || 'Folder Created');
            } else {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    };
    return (
        <AntdComponents.Modal
            centered
            open={folderModalOpen}
            onCancel={() => SetFolderModalOpen(false)}
            footer={false}
            closeIcon={<ReactIcons.CloseIcon size={50}
            />}
            width={450}
        >
            <div className="">
                <AntdComponents.Typography className='titleMedium font-semibold text-center'>Create Folder</AntdComponents.Typography>
                <AntdComponents.Input className='mb-2 input-box h-11 mt-5' placeholder='New Folder' value={folderName} onChange={(e) => { folderNameHandle(e) }} />
                <AntdComponents.Typography className='text-para text-Red'>{errorMsg && "Please enter folder name"}</AntdComponents.Typography>
                <div className="text-center mt-5">
                    <CustomButton title='Save Changes' isLoading={folderCreateLoading} onClick={() => folderError()} />
                    <AntdComponents.Typography className='paragraphSmall mt-2 cursor-pointer text-Blue' onClick={() => {SetFolderModalOpen(false);setFolderName('')}}>Cancel changes</AntdComponents.Typography>
                </div>
            </div>
        </AntdComponents.Modal>
    )
}

export default CreateFolder
