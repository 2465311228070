import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogoutAction } from "../redux/slices/authSlice";
import { RouterKeys } from "../Routes/RouterKeys";

const useAutoLogout = (loggedInTime,logOutAfterMinute) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
console.log('hclalla');

    useEffect(() => {
        if (!loggedInTime) return;
        // Convert loggedInTime to milliseconds
        const loginTimeMs = loggedInTime * 1000;
        const logoutTimeMs = loginTimeMs + logOutAfterMinute * 60 * 1000;
        const currentTimeMs = Date.now();
        const timeUntilLogout = logoutTimeMs - currentTimeMs;

        if (timeUntilLogout > 0) {
            const logoutTimer = setTimeout(() => {
                dispatch(LogoutAction()); // Clear user session
                navigate(RouterKeys.Auth.REGISTER_LOGIN); // Redirect to login
                console.log('AutmaticLogout1');
            }, timeUntilLogout);
            return () => clearTimeout(logoutTimer); // Cleanup on unmount
        } else {
            // If time is already exceeded, log out immediately
            // console.log('AutmaticLogout2');
            // dispatch(LogoutAction());
            // navigate(RouterKeys.Auth.REGISTER_LOGIN);
        }
    }, [loggedInTime, dispatch, navigate]);
};

export default useAutoLogout;
