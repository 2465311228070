import Logo from '../../assets/landing/logo.png'
import DownloadBack from '../../assets/landing/download.png'
import AboutBack from '../../assets/landing/about_vaultPd.png'
import Awesome from '../../assets/landing/awesome.png'
import Welcome from '../../assets/landing/welcome.png'
import WelcomeLeft from '../../assets/landing/welocomeLeft.png'
import AboutUs from '../../assets/landing/aboutUs.png'
import Add from '../../assets/landing/add_person.png'
import CheckList from '../../assets/landing/checklist.png'
import Share from '../../assets/landing/share.png'
import Video from '../../assets/landing/video.png'
import PlayIcon from '../../assets/landing/play-circle.png'
import QUOTES_IMAGE from '../../assets/landing/quotes.png'
import Android from '../../assets/landing/android.png'
import IosDownload from '../../assets/landing/ios.png'
import ContactUs from '../../assets/landing/contactUs.png'
export const landingImages = {
    LOGO:Logo,
    DOWNLOAD_BACK:DownloadBack,
    ABOUT_BACK:AboutBack,
    ABOUT_US:AboutUs,
    AWESOME:Awesome,
    WELCOME:Welcome,
    WELCOME_LEFT:WelcomeLeft,
    ADD:Add,
    CHECKLIST:CheckList,
    SHARE:Share,
    VIDEO:Video,
    PLAY_ICON:PlayIcon,
    QUOTES_IMAGE:QUOTES_IMAGE,
    ANDROID_IMAGE:Android,
    IOS_DOWNLOAD_IMAGE:IosDownload,
    CONTACT_US:ContactUs,
}