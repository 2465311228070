import React from 'react'
// import { ReactIcons } from '../../../utils/ReactIcons'
import * as AntdComponents from 'antd'
// import LoaderConfetti from '../../../components/LoaderConfetti'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import { RouterKeys } from '../../../Routes/RouterKeys'
import { useNavigate } from 'react-router-dom'

const array = [
    {
        icon: STATIC_IMAGES.TUTORIAL.ADD_PERSON,
        title: 'Add a trusted person',
        description: 'Share information with the people you trust, in 3 simple steps',
        link: RouterKeys.NON_Auth.TUTORIALS.ADD_PERSON
    },
    {
        icon: STATIC_IMAGES.TUTORIAL.CHECKLIST,
        title: 'Document checklists',
        description: 'Be prepared and organized by uploading key documents',
        link: RouterKeys.NON_Auth.TUTORIALS.DOCUMENT_CHECKLIST
    },
    {
        icon: STATIC_IMAGES.SHARE,
        title: 'Share documents',
        description: 'Family, friends, professionals, even companies can receive key files',
        link: RouterKeys.NON_Auth.TUTORIALS.SHARE_DOCUMENT
    },
]
const Training = () => {
    const navigate = useNavigate()
    return (
        <div className="bg-gray-100 relative w-full">
            <div className="absolute right-0 top-0 h-[300px] w-full max-w-sm rounded-bl-full bg-BlueTransparent blur-3xl"></div>
            <div className="absolute bottom-0 left-0 h-[300px] w-full max-w-sm rounded-bl-none rounded-tr-full bg-OrangeTransparent blur-3xl"></div>

            {/* Main Content */}
            <div className="relative flex items-center justify-center">
                <div className='rounded-10 shadow-formShadow p-4 w-full min-h-600'>
                    <div className="back-box block">
                        <AntdComponents.Typography className='titleMedium text-center'>Training</AntdComponents.Typography>
                        {/* <AntdComponents.Typography className=''></AntdComponents.Typography> */}
                    </div>
                    <div className="">
                        {/* <div className="confetti w-36 h-28 mx-auto mt-2 relative">
                            <LoaderConfetti />
                        </div> */}
                        <div className="text-center w-full mt-7">
                            <AntdComponents.Typography className='titleMedium'>Welcome</AntdComponents.Typography>
                            <AntdComponents.Typography className='paragraph font-normal mt-2'>You have successfully subscribed to VaultPD. Dive in by watching training videos <br/>and adding your secondary account holder.</AntdComponents.Typography>
                            <div className="mt-3 mx-auto pb-10 tutorial_paren_div">
                                <AntdComponents.Row gutter={[16,16]}>
                                    {
                                        array?.map((item, index) => {
                                            return (
                                                <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12} key={index} >
                                                    <div className="outline-button rounded-0.3 mt-5 h-[150px] overflow-y-auto"  role='button' tabIndex={0} onClick={() => navigate(item?.link)} onKeyDown={(e) => {
                                                        if (e.key === 'Enter' || e.key === ' ') {
                                                            navigate(item?.link)
                                                        }
                                                    }}>
                                                        <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-normal items-center gap-0 md:gap-4 p-2 bg-White rounded-0.2 h-[100%]">
                                                            <div className="">
                                                                <AntdComponents.Avatar src={item?.icon} size={60} />
                                                            </div>
                                                            <div className="text-center md:text-start">
                                                                <AntdComponents.Typography className='titleSmall'>{item?.title}</AntdComponents.Typography>
                                                                <AntdComponents.Typography className='text-para'>{item?.description}</AntdComponents.Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AntdComponents.Col>
                                            )
                                        })
                                    }
                                </AntdComponents.Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Training
