import React from 'react'
import * as AntdComponents from 'antd'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import { ReactIcons } from '../../../utils/ReactIcons'
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, signInWithPopup } from 'firebase/auth'
import { firebaseAuth } from '../../../Firebase/firebase'
import { useDynamicMutationMutation } from '../../../redux/service/apiSlice'
import { NON_AUTH } from '../../../utils/Endpoints'
import CustomToast from '../../../utils/CustomToast'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { RouterKeys } from '../../../Routes/RouterKeys'
import { AuthData, DeviceIdStore } from '../../../redux/slices/authSlice'
import { baseUrl, linkedInClientId } from '../../../utils/Constant'
import { DeviceUUID } from 'device-uuid';
import axios from 'axios'

const SocialLogin = () => {
    const deviceId = new DeviceUUID().get();
    const [socialMutation, { isLoading }] = useDynamicMutationMutation();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // get code from url
    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get("code");

        if (code) {
            generateToken(code)
        }
    }, []);

    // generate access token
    const generateToken = async (code) => {
        try {

            const response = await axios.post(`${baseUrl}${'user/auth/linkedIn/accessToken'}`, {
                code: code
            });
            let linkedInUser = response?.data?.data
            console.log('data>>>>>', response?.data);
            let payload ={
                login_source :'linkedIn',
                uid:linkedInUser?.sub,
                email:linkedInUser?.email,
                phone_number:'',
                user_name:`${linkedInUser?.given_name} ${linkedInUser?.family_name}`,
                photoURL:linkedInUser?.picture,
            }
            socialApiHit(payload)
            // getProfile(accessToken)
        } catch (error) {
            console.error("Error fetching LinkedIn profile:", error.message);
        }
    };


    // api hit for all social login
    const socialApiHit = async (values) => {
        let payload = {
            "login_source": values?.login_source,
            "social_auth": values?.uid,
            "device_country": "US",
            "device_name": "web",
            "device_id": deviceId,
            "email": values?.providerData?.[0]?.email,
            "phone_number": values?.phone_number?values?.phone_number:values?.providerData?.[0]?.phoneNumber || '',
            "user_name":values?.user_name?values?.user_name:values?.providerData?.[0]?.displayName || '',
            "os_type": 'web',
            "profile_pic": values?.photoURL || ''
        }

        let requestData = {
            endpoint: NON_AUTH.SOCIAL_LOGIN,
            method: 'POST',
            body: payload,
        };

        try {
            const { data, error } = await socialMutation(requestData);
            if (data?.data?.token) {
                CustomToast('s', data?.message)
                dispatch(AuthData(data?.data));
                dispatch(DeviceIdStore(deviceId))
                navigate(`/${RouterKeys.NON_Auth.HOME.HOME}`)
            } else {
                CustomToast('e', error?.data?.message || 'Login failed')
            }
        } catch (err) {
            // CustomToast('e', 'An unexpected error occurred.');
        }
    }
    // google login
    const handleGoogleLogin = async () => {
        try {
            const provider = new GoogleAuthProvider();
            provider.addScope('email')
            let data = await signInWithPopup(firebaseAuth, provider);
            console.log('data', data);
            let userData = data?.user
            socialApiHit({ ...userData, login_source: 'google', })
        } catch (error) {
            const errorCode = error?.code;
            const errorMessage = error?.message;
            const email = error?.customData?.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.log('errorMessage', errorMessage);
            console.log("Failure Google Login ", credential, errorCode, errorMessage, email)
        }
    }
    // facebook login
    const handleFacebookLogin = async () => {
        try {
            const provider = new FacebookAuthProvider();
            provider.addScope('email')
            let data = await signInWithPopup(firebaseAuth, provider);
            const credential = FacebookAuthProvider.credentialFromResult(data);
            console.log('data', data, 'credential', credential);
            let userData = data?.user
            socialApiHit({ ...userData, login_source: 'google', })
        } catch (error) {
            const errorCode = error?.code;
            const errorMessage = error?.message;
            const email = error?.customData?.email;
            const credential = FacebookAuthProvider.credentialFromError(error);
            console.log('errorMessage', errorMessage);
            console.log("Failure Google Login ", credential, errorCode, errorMessage, email)
        }
    }
    // linkedin login
    const handleLinkedInLogin = () => {
        const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedInClientId}&redirect_uri=${encodeURIComponent("https://www.dev.vaultpd.com/login_user")}&scope=openid%20profile%20email`;

        window.location.href = linkedInAuthUrl;
    };
    // const handleLinkedInLogin = () => {
    //     const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedInClientId}&redirect_uri=${encodeURIComponent("https://www.dev.vaultpd.com/login_user")}&scope=email%20r_liteprofile`;
    //     window.location.href = linkedInAuthUrl;
    // };
    
    // apple login
    const appleLoginFunc = async () => {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        signInWithPopup(firebaseAuth, provider)
            .then((result) => {
                const userData = result.user;
                socialApiHit({ ...userData, login_source: 'apple' })
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                var email = error.email;
                var credential = error.credential;
                console.log("Apple Login Failure ", errorCode, errorMessage, email, credential)
            });
    }
    return (
        <AntdComponents.Spin spinning={isLoading}>
            <div className="flex items-center flex-col md:flex-row justify-center gap-5 my-4">
                <AntdComponents.Button block className='bg-White p-6 font-semibold text-para hover:text-Black border-none' icon={<AntdComponents.Avatar src={STATIC_IMAGES.GOOGLE_LOGO} />} iconPosition='start' onClick={() => handleGoogleLogin()}>Continue with Google</AntdComponents.Button>
                <AntdComponents.Button block className='bg-Black p-6 font-semibold text-para text-White border-none' icon={<AntdComponents.Avatar className='social-icon' src={STATIC_IMAGES.Apple_LOGO} />} iconPosition='start' onClick={() => appleLoginFunc()}>Sign in with Apple</AntdComponents.Button>
            </div>
            <div className="flex items-center justify-center gap-4">
                <ReactIcons.FbIcon onClick={() => handleFacebookLogin()} />
                <AntdComponents.Avatar src={STATIC_IMAGES.LinkedinIcon} size={62} className='cursor-pointer' onClick={() => handleLinkedInLogin()} />
            </div>
        </AntdComponents.Spin>
    )
}

export default SocialLogin
