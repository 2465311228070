import React from 'react'
import * as AntdComponents from 'antd'
import { acceptImageType, fileUrl, onlyTenNumberInputs, whiteSpaceError } from '../../../utils/Constant'
import CustomButton from '../../../components/CustomButton'
import { STATIC_IMAGES } from '../../../utils/StaticImages'
import { useDynamicMutationMutation, useDynamicQueryQuery } from '../../../redux/service/apiSlice'
import { AUTH } from '../../../utils/Endpoints'
import CustomToast from '../../../utils/CustomToast'
import { ReactIcons } from '../../../utils/ReactIcons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RouterKeys } from '../../../Routes/RouterKeys'
import { QueryKeys } from '../../../utils/RTKKeys'

const AddContact = () => {
    const navigate = useNavigate()
    const [searchParam] = useSearchParams()
    const id = searchParam.get('id')
    const [contactForm] = AntdComponents.Form.useForm()
    const [triggerDetailUpdate, { isLoading: detailUpdateLoading }] = useDynamicMutationMutation();
    const [imageFileUrl, setImageFileUrl] = React.useState({})
    const [viewUrl, setViewUrl] = React.useState()

    // contact detail
    const { data } = useDynamicQueryQuery({
        endpoint: AUTH.CONTACTS.CONTACTS_DETAIL,
        params: { contact_id: id },
        key: QueryKeys.CONTACT_DETAIL,
    },
        { skip: !id, refetchOnMountOrArgChange: true }
    )
    let detailData = data?.data?.data
    // prefilled data
    React.useEffect(() => {
        if (detailData?._id) {
            contactForm.setFieldsValue(({
                'first_name': detailData?.first_name,
                'last_name': detailData?.last_name,
                'phone': detailData?.phone,
                'email': detailData?.email,
            }))
            if (detailData?.profile_pic) {
                setViewUrl(`${fileUrl}${detailData?.profile_pic}`)
            }
        }
    }, [data])

    // file handle change
    const handleFileChange = (e) => {
        let file = e.target.files?.[0]
        setImageFileUrl(file)
        let blob = URL.createObjectURL(file)
        setViewUrl(blob)
    }
    // submit and update
    const handleFinish = async (values) => {
        let formData = new FormData()
        formData.append('first_name', values?.first_name)
        formData.append('last_name', values?.last_name)
        formData.append('contact_pic', imageFileUrl)
        if (detailData?._id) {
            formData.append('contact_id', detailData?._id)
        } else {
            formData.append('phone', values?.phone)
            formData.append('email', values?.email)
        }
        let requestData = {
            endpoint: detailData?._id ? AUTH.CONTACTS.CONTACTS_UPDATE : AUTH.CONTACTS.CONTACTS_ADD,
            method: detailData?._id ? 'PUT' : 'POST',
            body: formData,
            key: [QueryKeys.CONTACT_DETAIL,QueryKeys.CONTACT_LIST]
        };

        try {
            const { data, error } = await triggerDetailUpdate(requestData);
            if (data?.data) {
                CustomToast('s', data?.message);
                setImageFileUrl({})
                if (!detailData?._id) {
                    contactForm.resetFields()
                    navigate(-1)
                }
            } else {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    }
    return (
        <div className='content_common'>
            <div className="flex items-center justify-between">
                <ReactIcons.BackIcon onClick={() => navigate(RouterKeys.NON_Auth.CONTACTS.CONTACTS)} />
                <div className="">
                    <AntdComponents.Typography className='titleMedium text-center mt-5'>Add A trusted Contacts</AntdComponents.Typography>
                    <AntdComponents.Typography className="paragraph text-center">For quick sharing, add a trusted person to your vault. The Trusted<br/>contacts can receive files you choose to share with them.</AntdComponents.Typography>
                </div>
                <p></p>
            </div>
            <div className="w-full md:w-1/2 m-auto">
                <div className="text-center mt-5">
                    <input id='profile_pic' type='file' accept={acceptImageType} className='hidden' onChange={(e) => handleFileChange(e)} />
                    <label htmlFor='profile_pic' className='cursor-pointer relative'>
                        <AntdComponents.Avatar src={viewUrl ? viewUrl : STATIC_IMAGES.CONTACTS.ADD_CONTACT_CAM} size={100} />
                        {
                            id &&
                            <div className="absolute -top-9 -left-0">
                                <AntdComponents.Image src={STATIC_IMAGES.EDIT_PROFILE} height={30} preview={false} />
                            </div>
                        }
                    </label>
                    <AntdComponents.Typography className='paragraphSmall mt-2'>Add a profile photo (Optional)</AntdComponents.Typography>
                </div>
                {/* form */}
                <AntdComponents.Form form={contactForm} layout='vertical' onFinish={(values) => handleFinish(values)} requiredMark={false} className='mt-5'>
                    <AntdComponents.Row gutter={[20, 20]}>
                        <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <AntdComponents.Form.Item name="first_name" autoComplete="off" className='mb-1'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter first name!',
                                    },
                                    {
                                        validator: (_, value) => whiteSpaceError(value)
                                    },
                                ]}>
                                <AntdComponents.Input className='input-box' placeholder='First Name' />
                            </AntdComponents.Form.Item>
                        </AntdComponents.Col>
                        <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <AntdComponents.Form.Item name="last_name" autoComplete="off" className='mb-1'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter last name!',
                                    },
                                    {
                                        validator: (_, value) => whiteSpaceError(value)
                                    },
                                ]}>
                                <AntdComponents.Input className='input-box' placeholder='Last Name' />
                            </AntdComponents.Form.Item>
                        </AntdComponents.Col>
                        <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <AntdComponents.Form.Item name="email" autoComplete="off" className='mb-1'
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please Enter your email!',
                                    },
                                    {
                                        validator: (_, value) => whiteSpaceError(value)
                                    },
                                ]}>
                                <AntdComponents.Input
                                    className='input-box'
                                    placeholder='name@domain.com'
                                    prefix={<AntdComponents.Avatar src={STATIC_IMAGES.FORM.EMAIL} size={30} shape='square' />}
                                    readOnly={detailData?._id ? true : false}
                                />
                            </AntdComponents.Form.Item>
                        </AntdComponents.Col>
                        <AntdComponents.Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <AntdComponents.Form.Item name="phone" autoComplete="off" className='mb-1'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter your Phone!',
                                    },
                                    {
                                        validator: (_, value) => whiteSpaceError(value)
                                    },
                                ]}>
                                <AntdComponents.Input
                                    className='input-box'
                                    placeholder='Phone Number'
                                    onKeyPress={(e) => onlyTenNumberInputs(e)}
                                    readOnly={detailData?._id ? true : false}
                                />
                            </AntdComponents.Form.Item>
                        </AntdComponents.Col>
                    </AntdComponents.Row>
                    <div className="text-center mt-8">
                        <CustomButton title={'Add a contact'} isLoading={detailUpdateLoading} htmlType='submit' />
                    </div>
                </AntdComponents.Form>
            </div>
        </div>
    )
}

export default AddContact
