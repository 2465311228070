import React from 'react'
import * as AntdComponents from 'antd'
import CardCommon from './CardCommon'
import { useDynamicMutationMutation, useDynamicQueryQuery } from '../redux/service/apiSlice'
import { AUTH } from '../utils/Endpoints'
import { QueryKeys } from '../utils/RTKKeys'
import { fileUrl } from '../utils/Constant'
import { STATIC_IMAGES } from '../utils/StaticImages'
import moment from 'moment'
import CustomDivider from './CustomDivider'
import { ReactIcons } from '../utils/ReactIcons'
import CustomToast from '../utils/CustomToast'
import CommonDelete from './CommonDelete'
import CommonRename from './CommonRename'
import SkeletonLoader from '../utils/Loader/SkeletonLoader'
import ShareWithContact from './ShareWithContact'
import HistoryModal from '../screen/NonAuth/Home/HistoryModal'
import { RouterKeys } from '../Routes/RouterKeys'
import { useNavigate } from 'react-router-dom/dist'
// import Loader from './Loader'

const CommonDocumentDetails = ({ data, setFinalUploadFiles, popoverArr, byDetailPage, loading, colNum }) => {
    // popover related stated
    const [historyModalOpen, setHistoryModalOpen] = React.useState(false)
    const [popOverOpenData, setPopOverOpenData] = React.useState({})
    const [selectData, setSelectData] = React.useState({})
    const [deleteModal, setDeleteModal] = React.useState(false)
    const [renameModalOpen, setRenameModalOpen] = React.useState(false)
    const [shareOptionModal, setShareOptionModal] = React.useState(false)
    const [mainOpen, setMainOpen] = React.useState(false)
    const navigate = useNavigate()
    // documents detail
    const { data: documentData } = useDynamicQueryQuery({
        endpoint: AUTH.HOME.DOCUMENT.GET_DOCUMENT_DETAIL,
        params: { document_id: selectData?._id },
        key: QueryKeys.DOCUMENT_DETAIL,
    },
        { skip: !selectData._id, refetchOnMountOrArgChange: true }
    )
    const mainData = data


    // after rename and delete manage
    React.useEffect(() => {
        console.log('documentData', documentData, selectData?._id);
        if (documentData?.data?._id) {
            const mapArr = mainData?.map((item) => {
                if (item?._id === documentData?.data?._id) {
                    return {
                        ...item,
                        ...documentData?.data,
                    };
                }
                return item; // Ensure unchanged items are returned
            });
            setFinalUploadFiles(mapArr || []);
        }
    }, [documentData]);


    // delete folder
    const [deleteMutation, { isLoading: isDeleteLoading }] = useDynamicMutationMutation();
    // rename folder
    const [renameMutation, { isLoading: isRenameLoading }] = useDynamicMutationMutation();

    // popover content
    const PopOverContent = ({ data }) => {
        const openFunction = (e, item) => {
            e.stopPropagation()
            setPopOverOpenData({});
            setSelectData({ ...data })
            switch (item?.type) {
                case 'edit': {
                    setRenameModalOpen(true)
                }
                    break;
                case 'delete': {
                    setDeleteModal(true);
                }
                    break;
                case 'share': {
                    setShareOptionModal(true)
                    setMainOpen(true)
                }
                    break;
                case 'history': {
                    setHistoryModalOpen(true)
                }
                    break;
                case 'move': {
                    navigate(`${RouterKeys.NON_Auth.HOME.MOVE_FOLDERS}?file_id=${data?._id}`)
                }
                    break;
                default:
                    CustomToast('e', "You can't share the folder externally.")
            }
        }
        return (
            <div className="p-3 w-96">
                <div className="flex justify-end">
                    <ReactIcons.CloseIcon onClick={(e) => { e.stopPropagation(); setPopOverOpenData({}) }} />
                </div>
                {/* file detail */}
                <div className="flex gap-4" role='button' tabIndex={0}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            e.stopPropagation();
                        }
                    }}>
                    <AntdComponents.Avatar src={STATIC_IMAGES.FOLDER} size={40} />
                    <div className="">
                        <AntdComponents.Typography style={{ maxWidth: '200px' }} className='titleSmall text_truncate'>{data?.document_name}</AntdComponents.Typography>
                        <AntdComponents.Typography className='text-description font-normal text-DarkGrey'>{moment(data?.createdAt).format('lll')}</AntdComponents.Typography>
                    </div>
                </div>
                <CustomDivider className={'mt-4'} />
                {
                    popoverArr?.map((item, index) => {
                        return (
                            <div key={index} className="flex items-center gap-5 mt-3" role='button' tabIndex={0}
                                onClick={(e) => openFunction(e, item)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        openFunction(e, item)
                                    }
                                }}
                            >
                                <AntdComponents.Avatar src={item?.icon} size={40} />
                                <AntdComponents.Typography className='titleSmall font-normal'>{item?.title}</AntdComponents.Typography>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    // rename file 
    const handleRenameFunction = async (values, type) => {
        console.log("values", values, type);

        let payload = {
            "folder_id": selectData?.folder_id,
            "document_name": values?.rename_input,
            'document_id': selectData?._id
        };

        let requestData = {
            endpoint: AUTH.HOME.DOCUMENT.DOCUMENT_UPDATE,
            method: 'PUT',
            body: payload,
            key: [QueryKeys.DOCUMENT_DETAIL, QueryKeys.FOLDER_DETAIL]
        };

        try {
            const { data, error } = await renameMutation(requestData);
            if (data) {
                CustomToast('s', data?.message || 'File renamed successfully!');
                setRenameModalOpen(false)
                // setSelectData({})
            } else {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    }

    // delete file
    const handleDelete = async () => {
        let payload = {
            document_id: [selectData?._id],
        };

        let requestData = {
            endpoint: AUTH.HOME.DOCUMENT.DOCUMENT_DELETE,
            method: 'DELETE',
            body: payload,
            key: QueryKeys.FOLDER_DETAIL
        };

        try {
            const { data, error } = await deleteMutation(requestData);
            if (data) {
                CustomToast('s', data?.data?.message || 'File deleted successfully!');
                setDeleteModal(false)
                setSelectData('')
            } else {
                CustomToast('e', error?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            CustomToast('e', 'An unexpected error occurred.');
        }
    }
    return (
        <div>
            <AntdComponents.Row gutter={[20, 20]}>
                {loading ? <>
                    {
                        [1, 2]?.map((_, index) => {
                            return (
                                <AntdComponents.Col xs={24} sm={24} md={12} lg={colNum ? 8 : 12} xl={colNum ? 8 : 12} key={index}>
                                    <SkeletonLoader />
                                </AntdComponents.Col>
                            )
                        })
                    }
                </>
                    :
                    <>
                        {
                            mainData?.map((item, index) => {
                                // console.log('item', item,item?.document_type.split('/'));
                                let type = item?.document_type.split('/')
                                let url = ''
                                if (type?.[0] === 'image' && item?.document_url != '') {
                                    url = `${fileUrl}${item?.document_url}`
                                } else {
                                    url = STATIC_IMAGES.FILE
                                }
                                return (
                                    <AntdComponents.Col xs={24} sm={24} md={12} lg={colNum ? 8 : 12} xl={colNum ? 8 : 12} key={index}>
                                        <CardCommon data={item} image={url} size={60} shape={'square'} name={item?.document_name} cardPadding='p-2' popOverOpenData={popOverOpenData} setPopOverOpenData={setPopOverOpenData} PopOverContent={PopOverContent} byDetailPage={byDetailPage} />
                                    </AntdComponents.Col>
                                )
                            })
                        }
                    </>}
            </AntdComponents.Row>
            {/* delete modal */}
            {
                deleteModal &&
                <CommonDelete deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteMessage={`Do you want to delete this file ${selectData?.document_name}?`} deleteFunc={handleDelete} loading={isDeleteLoading} />
            }
            {/* folder rename */}
            {
                renameModalOpen &&
                <CommonRename renameModalOpen={renameModalOpen} setRenameModalOpen={setRenameModalOpen} image={STATIC_IMAGES.FOLDER} data={selectData} submitFunction={handleRenameFunction} loading={isRenameLoading} type={'File'} />
            }
            {/* history modal */}
            {
                historyModalOpen &&
                <HistoryModal historyModalOpen={historyModalOpen} setHistoryModalOpen={setHistoryModalOpen} selectData={selectData} />
            }
            {/* share modal */}
            {
                shareOptionModal &&
                <ShareWithContact shareOptionModal={shareOptionModal} setShareOptionModal={setShareOptionModal} mainOpen={mainOpen} setMainOpen={setMainOpen} selectData={selectData} />
            }

        </div>
    )
}

export default CommonDocumentDetails
