import React from 'react'
import * as AntdComponents from 'antd'
import OutlineButton from '../../../components/OutlineButton';
import CustomButton from '../../../components/CustomButton';
import { ReactIcons } from '../../../utils/ReactIcons';
import { useNavigate } from 'react-router-dom';
// import { useDynamicQueryQuery } from '../../../redux/service/apiSlice';
// import { AUTH } from '../../../utils/Endpoints';
// import { QueryKeys } from '../../../utils/RTKKeys';
// import { STATIC_IMAGES } from '../../../utils/StaticImages';

const AllPlans = () => {
    const [activeTab, setActiveTab] = React.useState('Monthly')
    const navigate = useNavigate()

    // get  listing
    // const { data: listData } = useDynamicQueryQuery({
    //     endpoint: AUTH.SUBSCRIPTION.GET_SUBSCRIPTION_LISTING,
    //     params: {},
    //     key: QueryKeys.SUBSCRIPTION_LIST
    // }, { refetchOnMountOrArgChange: true })
    // console.log('listData',listData);

    return (
        <div className='content_common'>
            <div className="flex items-center justify-between">
                <ReactIcons.BackIcon onClick={() => navigate(-1)} />
                <AntdComponents.Typography className='titleMedium text-center'>Pick Your Plan</AntdComponents.Typography>
                <p></p>
            </div>
            {/* Carousel */}
            {/* <div className="">
                <AntdComponents.Carousel
                    slidesToShow={1}
                    slidesToScroll={1}
                    autoplay={true}
                    infinite={false}
                >
                    <div className="text-center">
                        <AntdComponents.Avatar src={STATIC_IMAGES.SECURE} shape='square' size={100} />
                        <div className="">
                            <AntdComponents.Typography className='titleMedium'>Safe secure storage</AntdComponents.Typography>
                            <AntdComponents.Typography className='paragraphSmall font-normal'>Your important documents are in one central place,<br /> in digital form for easy access and sharing.</AntdComponents.Typography>
                        </div>
                    </div>
                    <div className="text-center">
                        <AntdComponents.Avatar src={STATIC_IMAGES.SHARE} shape='square' size={100} />
                        <div className="">
                            <AntdComponents.Typography className='titleMedium'>Share with ease</AntdComponents.Typography>
                            <AntdComponents.Typography className='paragraphSmall font-normal'>Key documents can be shared with individuals <br /> or businesses that you need to share the info with.</AntdComponents.Typography>
                        </div>
                    </div>
                    <div className="text-center">
                        <AntdComponents.Avatar src={STATIC_IMAGES.TUTORIAL.AUTO} shape='square' size={100} />
                        <div className="">
                            <AntdComponents.Typography className='titleMedium'>Car records</AntdComponents.Typography>
                            <AntdComponents.Typography className='paragraphSmall font-normal'>Safely store important car documents to prove<br /> ownership, license, title transfers for security.</AntdComponents.Typography>
                        </div>
                    </div>
                </AntdComponents.Carousel>
            </div> */}
            <div className="flex items-center justify-center w-full mt-5">
                <OutlineButton title={'Monthly'} classNameDiv={'w-full rounded-none'} className={`rounded-none animate_tab ${activeTab == 'Monthly' ? 'bg-transparent text-White' : 'bg-White'}`} onClick={() => { setActiveTab('Monthly'); }} />
                <OutlineButton title={'Annual'} classNameDiv={'w-full rounded-none'} className={`rounded-none animate_tab ${activeTab == 'Annual' ? 'bg-transparent text-White' : 'bg-White'}`} onClick={() => { setActiveTab('Annual'); }} />
            </div>
            <div className="mt-5">
                <AntdComponents.Row gutter={[20, 20]}>
                    {
                        [1, 2, 3]?.map((item, index) => {
                            return <AntdComponents.Col xs={24} sm={24} md={12} lg={8} xl={8} key={index}>
                                <div className={`${'border-2 border-MainPurple'} flex items-center justify-between p-7`}>
                                    <div className="">
                                        <AntdComponents.Typography className='paragraph text-Purple'>20 GB</AntdComponents.Typography>
                                        <AntdComponents.Typography className='paragraphSmall'>$20 a month</AntdComponents.Typography>
                                    </div>
                                    <div className="">
                                        <CustomButton title={'Subscribe'} className={'w-44'} />
                                        <AntdComponents.Typography className='description text-center text-Pink'>Most popular</AntdComponents.Typography>
                                    </div>
                                </div>
                            </AntdComponents.Col>
                        })
                    }

                </AntdComponents.Row>
            </div>
        </div>
    )
}

export default AllPlans
