import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ReactIcons } from '../../../../utils/ReactIcons'
import * as AntdComponents from 'antd'
import { STATIC_IMAGES } from '../../../../utils/StaticImages'
import { AutoKeyData, EstateKeyData, FinancialKeyData, HomeKeyData, KeyInfoKeyData, MedicalKeyData, PetsKeyData, photosData, RentalKeyData, ValuableKeyData } from '../../../../utils/Constant'
import CustomButton from '../../../../components/CustomButton'
import UploadModal from '../../../../components/UploadModal'
import { useDynamicMutationMutation } from '../../../../redux/service/apiSlice'
import UploadStatus from '../../Home/UploadStatus'

const KeyDetail = () => {
    const navigate = useNavigate()
    const [searchParam] = useSearchParams()
    const [checkListKeyName,setCheckListKeyName] = React.useState(false)
    const [image, setImage] = React.useState('')
    const [dataArr, setDataArr] = React.useState([])
    
    const [uploadModalOpen, setUploadModalOpen] = React.useState(false)
    const [blobImages, setBlobImages] = React.useState([])
    const [completeStatus, setCompleteStatus] = React.useState(false)

    // create folder 
    const [createFolderMutation, { isLoading: folderCreateLoading }] = useDynamicMutationMutation();
    // uploading file
    const [triggerDocumentUpload, { data: uploadedImages, isLoading: docUploading, isSuccess: docUploadSuccess, }] = useDynamicMutationMutation();

    React.useEffect(() => {
        let searchParamKey = searchParam.get('key')
        setCheckListKeyName(searchParamKey)
        if (searchParamKey === 'Medical') {
            setImage(STATIC_IMAGES.TUTORIAL.MEDICAL_KEY)
            setDataArr(MedicalKeyData)
        } else if (searchParamKey === 'Financial') {
            setImage(STATIC_IMAGES.TUTORIAL.FINANCIAL)
            setDataArr(FinancialKeyData)
        } else if (searchParamKey === 'Home') {
            setImage(STATIC_IMAGES.TUTORIAL.HOUSE)
            setDataArr(HomeKeyData)
        } else if (searchParamKey === 'Rental') {
            setImage(STATIC_IMAGES.TUTORIAL.RENTAL)
            setDataArr(RentalKeyData)
        } else if (searchParamKey === 'Estate Plans') {
            setImage(STATIC_IMAGES.TUTORIAL.ESTATE)
            setDataArr(EstateKeyData)
        } else if (searchParamKey === 'Pets') {
            setImage(STATIC_IMAGES.TUTORIAL.PET)
            setDataArr(PetsKeyData)
        } else if (searchParamKey === 'Auto') {
            setImage(STATIC_IMAGES.TUTORIAL.AUTO)
            setDataArr(AutoKeyData)
        } else if (searchParamKey === 'Valuables') {
            setImage(STATIC_IMAGES.TUTORIAL.Valuable)
            setDataArr(ValuableKeyData)
        } else if (searchParamKey === 'Identity') {
            setImage(STATIC_IMAGES.TUTORIAL.kEY_INFO)
            setDataArr(KeyInfoKeyData)
        }else if (searchParamKey === 'Photos') {
            setImage(STATIC_IMAGES.TUTORIAL.PHOTOS)
            setDataArr(photosData)
        }
    }, [])
    return (
        <>
            {
                (!docUploading && !completeStatus) ?
                    <div className='content_common w-full'>
                        <ReactIcons.BackIcon onClick={() => navigate(-1)} />
                        <div className="text-center">
                            <AntdComponents.Image src={image} preview={false} height={120} />
                            <div className="">
                                <AntdComponents.Typography className='titleLarge font-semibold'>{dataArr?.title}</AntdComponents.Typography>
                                <AntdComponents.Typography className='paragraphSmall'>{dataArr?.sub_title}</AntdComponents.Typography>
                                <AntdComponents.Typography className='paragraphSmall font-normal mt-2'>{dataArr?.description}</AntdComponents.Typography>
                            </div>
                        </div>
                        <div className="flex justify-center flex-col items-center">
                            <div className="">
                                {
                                    dataArr?.pointArr?.map((item, index) => {
                                        return (
                                            <div className="mt-3" key={index}>
                                                <AntdComponents.Typography className='paragraphSmall font-normal'>{item}</AntdComponents.Typography>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <CustomButton title={'Upload'} className={'my-8'} onClick={() => setUploadModalOpen(true)} />
                        </div>
                    </div>
                    :
                    <>
                        {
                            ((docUploading || docUploadSuccess) && completeStatus) &&
                            <UploadStatus folderCreateLoading={folderCreateLoading} docUploading={docUploading} setCompleteStatus={setCompleteStatus} docUploadSuccess={docUploadSuccess} uploadingImages={blobImages} uploadedImages={uploadedImages?.data || []} />
                        }
                    </>

            }
            {
                uploadModalOpen &&
                <UploadModal
                    uploadModalOpen={uploadModalOpen}
                    setUploadModalOpen={setUploadModalOpen}
                    setBlobImages={setBlobImages}
                    documentAdd={triggerDocumentUpload}
                    setCompleteStatus={setCompleteStatus}
                    isFolder={false}
                    createFolderFunc={createFolderMutation}
                    checkListKeyName={checkListKeyName}
                />
            }
        </>
    )
}

export default KeyDetail
